/** @format */
import projects, { projectsType, projectsActions } from "./modules/project";
import auth, { authType, authActions } from "./modules/auth";
import ui, { uiType, uiActions } from "./modules/ui";
import { LooseStaticObject } from "constants/interface";
import buildStore, { buildConfigs } from "./buildStore";

export type statesTypes = {
  projects: projectsType;
  auth: authType;
  ui: uiType;
};

export type actions = projectsActions | authActions | uiActions;

export const configs = buildConfigs([projects, auth, ui]);

const persists = [auth, ui];

const initState: any = buildStore({
  stores: { projects, auth, ui },
  persists,
});

export function userReducer(state: statesTypes, action: actions) {
  let persistConsole: any = localStorage.getItem("persist:console");
  persistConsole = persistConsole ? JSON.parse(persistConsole) : {};

  const allState: LooseStaticObject = { current: state };

  for (let i = 0; i < configs.length; i += 1) {
    if (configs[i].type === action.type) {
      allState.current = configs[i].state(state, action);
    }
  }

  for (let i = 0; i < persists.length; i += 1) {
    const persistsConfigs: any = persists[i].configs;
    const namespace: string = persists[i].namespace;

    if (persistsConfigs.length > 0) {
      for (let j = 0; j < persistsConfigs.length; j += 1) {
        if (persistsConfigs[j].type === action.type) {
          const values: any = { ...persistConsole };
          values[namespace] = JSON.stringify(allState.current[namespace]);
          localStorage.setItem("persist:console", JSON.stringify(values));
        }
      }
    }
  }

  return allState.current;
}

export default initState;
