/** @format */

import React, { useState, useEffect, useMemo, useRef, FC } from "react";
import styled from "styled-components";
import {
  range,
  drop,
  descend,
  sort,
  map,
  path,
  sum,
  addIndex,
  toString,
} from "ramda";
import { Space, Table, Select } from "antd";
import type { ColumnsType } from "antd/es/table";

import { formatValueByFormula, calcTotal } from "utils/widgetHelper";
import { wrapperColumn } from "components/shared/table/ATable";
import AdaptiveWidget from "components/shared/elements/AdaptiveWidget";
import { processRows } from "services/analysis";
import { capitalize } from "utils/stringUtil";

const StyledContainer = styled(AdaptiveWidget)`
  background: #fff;
  width: calc(100% - 4px);
  height: ${(props) => `calc(100% - ${props.headerHeight || 0}px)`};
  border-radius: 4px;
  padding: 0 2px;
  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 3px 16px;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 8px 16px;
  }
`;

function getRank(data: any, index: number) {
  let rank = index + 1;
  let prevTotal = data[index].total;

  for (let i = index - 1; i >= 0; i--) {
    if (data[i].total === prevTotal) {
      rank--;
    } else {
      break;
    }
  }

  return rank;
}

interface TableProps {
  data?: [];
  item?: any;
  name?: string;
  timeScope?: string;
  headerHeight?: number;
}

const AntTable: FC<TableProps> = ({
  data,
  item,
  timeScope,
  headerHeight = 160,
}) => {
  const [columns, setColumns] = useState<ColumnsType<any>>([]);
  const [dataSource, setDataSource] = useState<any>([]);
  const [itemSize, setItemSize] = useState<{ width: number; height: number }>({
    width: 1,
    height: 1,
  });

  const widgetInfo = useMemo(() => {
    return path(["widget"], item);
  }, [item]);

  const formulas = useMemo(() => {
    return path(["analysis", "formulas"], widgetInfo) || [];
  }, [widgetInfo]);

  useEffect(() => {
    const groupBys: [] = path(["groupBys"], data) || [];
    const series: [] = path(["dates"], data) || [];

    let groupCols: any[] = [];
    if (groupBys.length > 0) {
      groupCols = map(
        (i) => ({
          title: i,
          dataIndex: i,
          width: 130,
          fixed: "left",
        }),
        groupBys,
      );
    }

    const cols = [
      {
        title: "No",
        dataIndex: "no",
        width: 60,
        fixed: "left",
      },
      ...groupCols,
      {
        title: "Indicator",
        dataIndex: "indicator",
        width: 120,
        fixed: "left",
      },
    ];

    let xData: any = timeScope === "TOTAL" ? ["total"] : ["total", ...series];

    if (timeScope === "HOUR") {
      // xData = xData.length > 100 ? drop(xData.length - 100, xData) : xData;
    }

    const dateCols: any = map(
      (i: any) => ({
        title: capitalize(i),
        dataIndex: i,
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) => a[i] - b[i],
        width: i === "total" ? 100 : i.length * 13,
        render(value: any, record: any) {
          const valueNumber = formatValueByFormula(value, record.formula);
          return value ? valueNumber : 0;
        },
      }),
      xData,
    );

    setColumns([...cols, ...dateCols]);
  }, [data, timeScope]);

  useEffect(() => {
    const xData = path(["dates"], data) || [];
    const resultRows: [] = path(["details"], data) || [];
    const rows: [] = processRows(path(["details"], data) || [], formulas);
    const events: [] =
      formulas.length > 0
        ? addIndex(map)((i, idx) => `Customized Indicator ${idx + 1}`, formulas)
        : path(["indicatorNames"], data) || [];
    const groupBys: [] = path(["groupByNames"], data) || [];
    const dataRow: any = [];

    for (let i = 0; i < rows.length; i += 1) {
      const params: any = {};
      const byValues: [] = path([i, "byValues"], rows) || [];

      for (let m = 0; m < byValues.length; m += 1) {
        params[groupBys[m]] =
          typeof byValues[m] === "boolean"
            ? toString(byValues[m])
            : byValues[m];
      }
      for (let j = 0; j < events.length; j += 1) {
        const values: [] = path([i, "values"], rows);
        params.indicator = path([j], events);
        params.id = `${path([j], events)}${i}${j}`;

        params.total = calcTotal(
          formulas,
          resultRows,
          rows,
          path(["eventDisplayNames"], data),
          j,
          i,
        ); // sum(map((value) => sum(value), values));
        params.formula = path([j], formulas);
        if (timeScope !== "TOTAL") {
          for (let m = 0; m < values.length; m += 1) {
            const event = path([i, "values", m, j], rows);
            const dateTitle: string = path([m], xData) || "";
            params[dateTitle] = event;
          }
        }

        dataRow.push({ ...params });
      }
    }

    const descendingComparator: any = descend((i: any) => i.total);

    const rowRank = addIndex(map)(
      (i: any, idx: number) => ({ ...i, no: idx + 1 }),
      sort(descendingComparator, dataRow),
    );

    // const rowRank = dataRow.map((item: any, index: number) => ({
    //   ...item,
    //   no: index + 1,
    // }));

    setDataSource(rowRank);
  }, [data, formulas, timeScope]);

  return (
    <StyledContainer setItemSize={setItemSize} headerHeight={headerHeight}>
      <Table
        columns={wrapperColumn(columns)}
        dataSource={dataSource}
        pagination={{ size: "small", defaultPageSize: 15 }}
        rowKey={(record: any) =>
          path(["id"], record) ||
          path(["key"], record) ||
          path(["name"], record)
        }
        scroll={{ y: itemSize.height - 66, x: itemSize.width - 20 }}
      />
    </StyledContainer>
  );
};

export default AntTable;
