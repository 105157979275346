/** @format */

import React, { useReducer, FC } from "react";
import Root from "pages";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ReactQueryDevtools } from "react-query/devtools";

import * as api from "api";
import UserContext from "store/createContext";
import initState, { userReducer } from "store/initialState";
import { apiBase } from "utils/constants";

interface AppProps {
  apiHelper: any;
}

const ContextProvider: FC<AppProps> = (props) => {
  const [state, dispatch] = useReducer(userReducer, initState);

  api.init(state, apiBase, props.apiHelper);

  //   actions.onGlobalStateChange((state: any, prev: any) => {
  //     console.log(state, prev);
  //   });

  return (
    <UserContext.Provider value={{ state, dispatch: dispatch }}>
      <BrowserRouter>
        <Root {...props} />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </UserContext.Provider>
  );
};

export default ContextProvider;
