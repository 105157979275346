/** @format */

import React, { FC } from "react";
import styled from "styled-components";
import { map } from "ramda";
import { useParams } from "react-router-dom";
import { useSpring, animated } from "react-spring";

import { useSettingMenu } from "utils/menuUtil";
import MenuItem from "components/Main/MenuItem";

const Containter = styled(animated.div)`
  width: 200px;

  background: #fff;
  margin-right: 12px;
  margin-bottom: 8px;
  margin-left: 8px;
  overflow-y: auto;
`;

interface MenuProps {
  mode: string;
  isStart?: boolean;
}

const Menu: FC<MenuProps> = ({ mode, isStart }) => {
  const params = useParams();
  const { projectId } = params;

  const memuItems = useSettingMenu(projectId);

  const containerSpring = useSpring({
    to: {
      marginTop: mode !== "collapsed" ? "16px" : "16px",
      height:
        mode !== "collapsed" ? "calc(100% - 134px)" : "calc(100% - 134px)",
    },
  });
  return (
    <Containter style={containerSpring}>
      {map(
        (i) => (
          <MenuItem data={i} key={i.key} mode={mode} isStart={isStart} />
        ),
        memuItems
      )}
    </Containter>
  );
};

export default Menu;
