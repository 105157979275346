/** @format */

import React, { FC, useRef, useEffect, useState, useCallback } from "react";
import styled from "styled-components";

const StyledContainter = styled.div`
  width: 100%;
  height: ${(props: any) => `${props.height}px` || "100%"};
  overflow: auto;
`;

interface AutoResizeNodeProps {
  children: any;
  diff?: number;
  setRef?: any;
  className?: any;
  style?: {};
  handleScroll?: any;
}

const AutoResizeNode: FC<AutoResizeNodeProps> = ({
  children,
  setRef = () => {},
  className,
  style,
  diff = 0,
  handleScroll,
}) => {
  const [height, setHeight] = useState(0);
  const autoRef = useRef<HTMLInputElement>(null);

  const resizeUpdate = useCallback((e?: any) => {
    const windowHeight = e ? e.target.innerHeight : window.innerHeight;
    if (autoRef.current) {
      const nodeRectTop = autoRef?.current?.getBoundingClientRect().top;
      setHeight(windowHeight - nodeRectTop);
    }
  }, []);

  useEffect(() => {
    resizeUpdate();
  }, [autoRef, resizeUpdate]);

  useEffect(() => {
    setRef(autoRef);
  }, [setRef, autoRef]);

  useEffect(() => {
    window.addEventListener("resize", resizeUpdate);
    return () => {
      window.removeEventListener("resize", resizeUpdate);
    };
  }, [resizeUpdate]);

  const styleProps = {
    height: height - diff,
  };

  const props: any = {};

  if (handleScroll) {
    props.onScroll = handleScroll;
  }

  return (
    <StyledContainter
      ref={autoRef}
      className={className}
      {...styleProps}
      style={style}
      {...props}
    >
      {typeof children === "function"
        ? children({ nodeRef: autoRef, height })
        : children}
    </StyledContainter>
  );
};

export default AutoResizeNode;
