import { useParams } from "react-router-dom";
import { Button, Select, Checkbox, Input, Radio, InputNumber } from "antd";
import {
  path,
  map,
  any,
  toUpper,
  sum,
  range,
  join,
  find,
  propEq,
  append,
  addIndex,
  filter,
} from "ramda";
import React, { FC, useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import AdaptiveWidget from "components/shared/elements/AdaptiveWidget";
import Line from "./Line";
import DateSelect from "./DateSelect";

dayjs.extend(utc);

const StyledContainer = styled(AdaptiveWidget)`
  width: calc(100% - 4px);
  height: ${(props) => `calc(100% - ${props.headerHeight || 0}px)`};
`;

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: max-content max-content auto max-content 1px;
  column-gap: 20px;
`;

const StyledDateFilter = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

interface LineProps {
  item: any;
  data: any;
  nodeRef: any;
  headerHeight?: number;
}

const DashboardLine: FC<LineProps> = ({
  item,
  data,
  nodeRef,
  headerHeight = 160,
}) => {
  const [dataType, setDataType] = useState<string>("trend");
  const [dateSection, setDateSection] = useState<string>("overall");

  const [itemSize, setItemSize] = useState<{ width: number; height: number }>({
    width: 1,
    height: 1,
  });

  const widgetInfo = useMemo(() => {
    return path(["widget"], item);
  }, [item]);

  const unit = useMemo(() => {
    return path(["analysis", "unit"], widgetInfo);
  }, [widgetInfo]);

  const onDates = useMemo(() => {
    return path(["onDates"], data);
  }, [data]);

  const startDates = useMemo(() => {
    return path(["startDates"], data);
  }, [data]);

  return (
    <StyledContainer setItemSize={setItemSize} headerHeight={headerHeight}>
      <StyledHeader>
        <Radio.Group
          defaultValue={dataType}
          size="small"
          onChange={(v: any) => {
            const value: string = v.target.value;
            setDataType(value);
          }}
        >
          <Radio.Button value="trend">Trend</Radio.Button>
          <Radio.Button value="comparison">Comparison</Radio.Button>
        </Radio.Group>
        <DateSelect
          parentRef={nodeRef.current}
          analysis={widgetInfo.analysis}
          dataType={dataType}
          onDates={onDates}
          startDates={startDates}
          unit={unit}
          size="small"
          dateSection={dateSection}
          setDateSection={setDateSection}
        />
        {/* <StyledDateFilter>
          Date
          <Select
            value={dateSection}
            style={{ width: 140 }}
            onChange={onChangeType}
            getPopupContainer={() => parentRef || document.body}
            options={options}
          />
        </StyledDateFilter> */}
      </StyledHeader>
      <Line
        dateSection={dateSection}
        dataType={dataType}
        analysis={widgetInfo.analysis}
        retentionResult={data}
        itemSize={itemSize}
        height={200}
      />
    </StyledContainer>
  );
};

export default DashboardLine;
