/** @format */

import React, { FC } from "react";
import { map } from "ramda";
import { useSpring, animated } from "react-spring";

import SubMenuItem from "./SubMenuItem";

interface SpringItemProps {
  data: [
    {
      key: number;
      link: string;
      name: string;
      length: number;
    }
  ];
  isVisible: boolean;
  mode?: string;
  isStart?: boolean;
}

const SpringItem: FC<SpringItemProps> = ({
  data,
  isVisible,
  mode,
  isStart,
}) => {
  // const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  const styles = useSpring({
    to: {
      opacity: isVisible && mode !== "collapsed" ? 1 : 0,
      y: isVisible && mode !== "collapsed" ? 0 : 24,
      height:
        isVisible && mode !== "collapsed" ? (data || []).length * (38 + 4) : 0,
    },
    config: { duration: 300 },
    pause: isStart,
    delay: 100,
  });

  return (
    <animated.div
      style={{ overflow: "hidden", position: "relative", ...styles }}
    >
      {map(
        (item) => (
          <SubMenuItem data={item} key={item.key} />
        ),
        data || []
      )}
    </animated.div>
  );
};

export default SpringItem;
