/** @format */

import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { range, map, path, sum, join, addIndex } from "ramda";
import { Select } from "antd";

import AdaptiveWidget from "components/shared/elements/AdaptiveWidget";

import { tooltip } from "../Helper";
import Line from "../common/Line";

const StyledContainer = styled(AdaptiveWidget)`
  background: #fff;
  width: 100%;
  height: ${(props) => `calc(100% - ${props.headerHeight || 0}px)`};
  border-radius: 4px;
`;

const seriesBase = {
  type: "line",
  symbol: "roundRect",
  connectNulls: true,
  smooth: false,
};

const FunnelLine = ({
  valueUnit,
  item,
  data,
  headerHeight,
}: {
  valueUnit?: any;
  item?: any;
  data?: any;
  headerHeight?: number;
}) => {
  const [itemSize, setItemSize] = useState<{ width: number; height: number }>({
    width: 1,
    height: 1,
  });

  const [current, setCurrent] = useState<number>(0);
  const [stepOptions, setStepOptions] = useState<any>([]);
  const [datasource, setDatasource] = useState<any>([]);
  const detailResult = useMemo(() => {
    return data;
  }, [data]);
  const { dates, stepNames, groupBys, groupByValues } = detailResult;
  const funnelDetail = path(["funnelResult", "byDates"], detailResult);

  useEffect(() => {
    setStepOptions(
      addIndex(map)(
        (i, idx) => ({
          value: idx,
          label: idx === 0 ? "Total" : `Step ${idx}`,
        }),
        stepNames,
      ),
    );
  }, [stepNames]);

  useEffect(() => {
    const groupLength = (
      path([0, "dateFunnel", 0, "groupByValues"], funnelDetail) || []
    ).length;

    const seriesLine = addIndex(map)(
      (group, groupIdx) => {
        const seriesData = map((i) => {
          const steps: any = path(["dateFunnel"], i);
          let rate: any = "0%";
          if (current > 0) {
            const convert = path(
              [
                current - 1 === 0 ? current : current - 1,
                "groupByValues",
                groupIdx,
              ],
              steps,
            );

            const nextConvert = path(
              [current, "groupByValues", groupIdx],
              steps,
            );
            rate = nextConvert === 0 ? 0 : nextConvert / convert;
          } else {
            const convert = path([0, "groupByValues", groupIdx], steps);
            const lastConvert = path(
              [steps.length - 1, "groupByValues", groupIdx],
              steps,
            );

            rate = convert === 0 ? 0 : lastConvert / convert;
          }

          return rate;
        }, funnelDetail || []);

        const name = current > 0 ? stepNames[current - 1] : "Total";
        return {
          name: name,
          groupBys:
            groupByValues.length > 0 ? join(",", groupByValues[groupIdx]) : "",
          data: map(
            (i) => ({
              value: (i * 100).toFixed(0),
              units: "%",
              name:
                groupByValues.length > 0
                  ? join(",", groupByValues[groupIdx])
                  : "",
              eventName: name,
              groupBy: path([0], groupBys),
            }),
            seriesData,
          ),
          ...seriesBase,
          showSymbol: false,
        };
      },
      range(1, groupLength + 1),
    );

    setDatasource(seriesLine);
  }, [current, groupBys, groupByValues, stepNames, funnelDetail]);

  const handleChange = (value: any) => {
    setCurrent(value);
  };

  return (
    <StyledContainer setItemSize={setItemSize} headerHeight={headerHeight}>
      <div>
        <Select
          defaultValue={current}
          style={{ width: 120 }}
          onChange={handleChange}
          options={stepOptions}
        />
      </div>
      <Line
        height="calc(100% - 32px)"
        data={{ label: dates, datasource: datasource }}
        tooltip={tooltip(groupBys)}
        axisLabelFormatter={(value: any) => {
          return value;
        }}
      />
    </StyledContainer>
  );
};

export default FunnelLine;
