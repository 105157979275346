import React, { FC } from "react";

const ColseIcon: FC<any> = ({
  width = 32,
  height = 32,
  fill = "#647379",
  ...restProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <rect
      x="9.63623"
      y="21.3135"
      width="16"
      height="2"
      transform="rotate(-45 9.63623 21.3135)"
    />
    <rect
      x="11.0503"
      y="10"
      width="16"
      height="2"
      transform="rotate(45 11.0503 10)"
    />
  </svg>
);

export default ColseIcon;
