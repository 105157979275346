/** @format */

import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { map, path, split } from "ramda";
import { NavLink, useParams } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { Popover } from "antd";

import { auid } from "utils/stringUtil";
import SpringItem from "./SpringItem";
import Arrow from "assets/menu/Arrow";
import CollapsedSubMenuItem from "./CollapsedSubMenuItem";

const StyleLabel = styled(animated.div)`
  line-height: 20px;
  color: #2e2e2e;
  font-size: 14px;
  margin-left: 30px;
  margin-top: 2px;
`;

const StyledCollapsed = styled(animated.div)<{ mode: any }>`
  width: 40px;
  height: 38px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transition: background-color 0.5s;
  background: ${(props: { mode?: string }) =>
    props.mode === "collapsed" && "#f7f8fa"};
  &:hover {
    background: ${(props: { mode?: string }) =>
      props.mode === "collapsed" && "#e8f3ff"};
  }
`;

const StyledRowLeft = styled(animated.div)`
  display: flex;
  align-items: center;
`;

const StyleItem = styled.div<{ mode: any }>`
  width: 100%;
  height: 38px;
  margin-bottom: 5px;
  a {
    color: #4f4f4f;
    text-decoration: none;
  }
  &:hover {
    background: ${(props: { mode?: string }) =>
      props.mode !== "collapsed" ? "#f7f8fa" : "none"};
  }
  a.routeActive ${StyleLabel} {
    color: #229bff;
  }
  a.routeActive ${StyledCollapsed} {
    background: ${(props: { mode?: string }) =>
      props.mode === "collapsed" && "#e8f3ff"};
  }
`;

const StyledRow = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  position: relative;
`;

interface MenuItemProps {
  data: {
    key: number;
    link: string;
    name: string;
    icon: any;
    child?: any;
    uriName: string;
  };
  mode?: string;
  isStart?: boolean;
}

const MenuItem: FC<MenuItemProps> = ({ data, mode, isStart }) => {
  const [isVisible, toggleVisible] = useState(false);
  const [isCurrentPage, toggleCurrentPage] = useState(false);
  const [open, setOpen] = useState(false);
  const params = useParams();

  const rowSpring = useSpring({
    to: {
      paddingLeft: mode !== "collapsed" ? "12px" : "6px",
      paddingRight: mode !== "collapsed" ? "12px" : "6px",
    },
    config: { duration: 200 },
  });

  const expandSpring = useSpring({
    to: {
      opacity: mode !== "collapsed" ? 1 : 0,
      x: mode === "collapsed" ? 10 : 0,
    },
    config: { duration: 200 },
  });

  const collapsedSpring = useSpring({
    to: {
      x: mode === "collapsed" ? 0 : -13,
    },
    config: { duration: 200 },
  });

  const nameSpring = useSpring({
    to: {
      opacity: mode !== "collapsed" ? 1 : 0,
    },
  });

  const arrowDirection = useSpring({
    to: { rotate: isVisible ? 180 : 90, opacity: mode !== "collapsed" ? 1 : 0 },
  });

  const Icon: any = data.icon;

  const content = map(
    (item) => (
      <div
        key={item.key}
        onClick={() => {
          setOpen(false);
        }}
      >
        <CollapsedSubMenuItem data={item} key={item.key} />
      </div>
    ),
    data.child || [],
  );

  useEffect(() => {
    let pathName = params["*"] || "";
    if (pathName.indexOf("/") > 0) {
      pathName = split("/", pathName)[0];
    }
    toggleCurrentPage(pathName === data.uriName);
  }, [params, data]);

  useEffect(() => {
    toggleVisible(isCurrentPage);
  }, [isCurrentPage]);

  const computedClassName = ({ isActive }: { isActive: boolean }) => {
    return isActive ? "routeActive" : "";
  };

  const expandNode = (
    <StyledRowLeft style={expandSpring}>
      {/* <Icon
        isActive={isVisible}
        style={{ marginRight: "17px", display: "none" }}
      /> */}
      <StyleLabel style={nameSpring}>{data.name}</StyleLabel>
    </StyledRowLeft>
  );

  const collapsedNode = (
    <StyledCollapsed
      style={collapsedSpring}
      mode={mode}
      onClick={() => {
        setOpen(false);
      }}
    >
      <Icon isActive={isCurrentPage} />
    </StyledCollapsed>
  );

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const collapsedChildNode =
    (path(["child"], data) || []).length > 0 && mode === "collapsed" ? (
      <Popover
        content={content}
        placement="rightTop"
        onOpenChange={handleOpenChange}
        overlayClassName="menu-popover"
        open={open}
      >
        {collapsedNode}
      </Popover>
    ) : (
      collapsedNode
    );

  return (
    <>
      <StyleItem
        mode={mode}
        onClick={() => {
          if (mode !== "collapsed") {
            toggleVisible((i) => !i);
          }
        }}
      >
        <NavLink
          to={
            isCurrentPage && (path(["child"], data) || []).length > 0
              ? "#"
              : data.link
          }
          className={computedClassName}
        >
          <StyledRow
            style={rowSpring}
            data-auid={auid("insights", "sidebar", `${data.name}`)}
          >
            {expandNode}
            {collapsedChildNode}
            {data.child && data.child.length > 0 && (
              <animated.div style={arrowDirection}>
                <Arrow />
              </animated.div>
            )}
          </StyledRow>
        </NavLink>
      </StyleItem>
      <SpringItem
        data={data.child}
        isVisible={isVisible}
        mode={mode}
        isStart={isStart}
      />
    </>
  );
};

export default MenuItem;
