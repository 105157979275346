/** @format */
import React, { useEffect, FC } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { path } from "ramda";
import INSIGHT_SDK from "insights-sdk/dist/es/main";

import { titleName } from "components/shared/common";
import { userId } from "utils/constants";
import WidgetDetail from "components/Dashboard/WidgetDetail";

interface MainProps {}

const Widget: FC<MainProps> = () => {
  const params = useParams();

  const widgetId: string = path(["widgetId"], params) || "";
  const projectId: string = path(["projectId"], params) || "";
  const dashboardId: string = path(["dashboardId"], params) || "";
  const widgetType: any = path(["widgetType"], params);

  //   useEffect(() => {
  //     INSIGHT_SDK.trackPageView({
  //       distinct_id: userId,
  //       attributes: { $page_title: "Widget", $has_logged_in: true },
  //     });
  //     return () => {
  //       INSIGHT_SDK.flush();
  //     };
  //   }, []);

  return (
    <>
      <Helmet>
        <title>{`${titleName}`}</title>
      </Helmet>
      <WidgetDetail
        widgetId={widgetId}
        projectId={projectId}
        dashboardId={dashboardId}
        widgetType={widgetType}
      />
    </>
  );
};

export default Widget;
