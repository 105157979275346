/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";
import { useSpring, animated } from "react-spring";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  onClick?: () => void;
  style: {};
  isActive: boolean;
}

const Analysis = forwardRef(
  (
    {
      width = 15,
      height = 14,
      className,
      onClick,
      fill,
      style,
      isActive,
      ...restProps
    }: IconProps,
    ref
  ) => {
    const rectStyles = useSpring({
      to: { fill: isActive ? "#229BFF" : "#949494" },
      config: { duration: 500 },
    });
    const pathStyles = useSpring({
      to: { fill: isActive ? "#5AD3FF" : "#C9CDD4" },
      config: { duration: 500 },
    });
    return (
      <svg
        width={width}
        height={height}
        className={className}
        style={style}
        onClick={onClick}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <animated.rect
          x="0.5"
          width="11"
          height="10"
          rx="1"
          fill="#949494"
          style={rectStyles}
        />
        <path
          d="M3 6.5L5 4.5L7 5.5L9 3.5"
          stroke="white"
          strokeLinecap="round"
        />
        <g style={{ mixBlendMode: "multiply" }}>
          <animated.path
            d="M9.5 12C9.5 11.2044 9.13125 10.4413 8.47487 9.87868C7.8185 9.31607 6.92826 9 6 9C5.07174 9 4.1815 9.31607 3.52513 9.87868C2.86875 10.4413 2.5 11.2043 2.5 12L9.5 12Z"
            fill="#C9CDD4"
            style={pathStyles}
          />
        </g>
      </svg>
    );
  }
);

export default Analysis;
