import { path, addIndex, range, sum, map, join } from "ramda";
import {
  roundFun,
  generateNumberToLetterMap,
  calculateFormula,
  formatNumberWithUnit,
} from "utils/stringUtil";

export const getWidget = (widget: any) => {
  const widgetType = path(["widgetType"], widget);
  const widgetInfo = path(["eventWidget"], widget);
  const funnelWidgetInfo = path(["funnelWidget"], widget);
  const retentionWidgetInfo = path(["retentionWidget"], widget);
  if (widgetType === "FUNNEL" || funnelWidgetInfo) {
    return funnelWidgetInfo;
  }
  if (widgetType === "RETENTION" || retentionWidgetInfo) {
    return retentionWidgetInfo;
  }
  return widgetInfo;
};

export const addPreviousValue = (data: any) => {
  let previousValue = null;
  let previousPercent: any = null;
  const arr = [];
  for (let i = 0; i < data.length; i++) {
    const currentGroup = data[i];
    const percent = previousValue
      ? (currentGroup.value / previousValue) * 100
      : 0;

    if (previousValue !== null) {
      arr.push({
        ...data[i],
        previousValue,
        percent: percent > 100 ? 100 : percent,
        previousPercent: previousPercent > 100 ? 100 : previousPercent,
      });
    } else {
      arr.push({
        ...data[i],
        percent: path([i, "value"], data) === 0 ? 0 : 100,
      });
    }

    previousPercent =
      previousPercent || previousPercent === 0
        ? previousPercent * (percent / 100)
        : currentGroup.value === 0
        ? 0
        : 100;
    previousValue = currentGroup.value;
  }
  return arr;
};

export const summarizeStepByTotal = (
  byTotal: any,
  displayName?: any,
  groupBys?: any
) => {
  const groupLength =
    (groupBys || []).length === 0 ? 1 : (groupBys || []).length;

  return addIndex(map)((group, groupIdx) => {
    return addIndex(map)((i, idx) => {
      const name = displayName[idx];
      return {
        value: i,
        groupBys: name,
        name: name,
        key: `${name}_${idx}`,
        displayName: name,
      };
    }, byTotal[groupIdx]);
  }, range(1, groupLength + 1));
};

export const summarizeStep = (
  detail: any,
  displayName?: any,
  groupBys?: any
) => {
  const groupLength = (
    path([0, "dateFunnel", 0, "groupByValues"], detail) || []
  ).length;

  return addIndex(map)((group, groupIdx) => {
    return addIndex(map)((i, idx) => {
      const sumValue = sum(
        map(
          (d) => path(["dateFunnel", idx, "groupByValues", groupIdx], d),
          detail
        )
      );
      return {
        value: sumValue,
        groupBys:
          groupBys && groupBys.length > 0 && groupBys[groupIdx]
            ? join(",", groupBys[groupIdx])
            : "",
        name: i,
        key: `${i}_${idx}`,
        displayName: displayName[idx],
      };
    }, displayName);
  }, range(1, groupLength + 1));
};

export const transformConversionPeriod = (query: any) => ({
  conversionPeriod:
    path(["analysis", "funnel", "conversionPeriod"], query) * 24 * 60 * 60,
});

export const formatValueByFormula = (value: any, formula: any) => {
  let decimal = 5;
  if (formula) {
    const format = path(["format"], formula);
    if (format === "TWO_DECIMAL") {
      decimal = 2;
      return formatNumberWithUnit(roundFun(value, decimal));
    }
    if (format === "PERCENTAGE") {
      decimal = 2;
      return `${formatNumberWithUnit(roundFun(value * 100, decimal))}%`;
    }
  }

  return formatNumberWithUnit(roundFun(value, decimal));
};

const setVariables = (values: any) => {
  const variables: any = {};
  addIndex(map)((num, numIdx) => {
    variables[generateNumberToLetterMap()[numIdx]] = num;
  }, values || []);

  return variables;
};

export const calcTotal = (
  formulas: any,
  resultRows: any,
  rows: any,
  events: any,
  index: number,
  rowIndex: number
) => {
  const values: [] = path([rowIndex, "values"], resultRows);
  if (formulas.length > 0) {
    const formula = path([index], formulas);
    const totals = addIndex(map)(
      (i, idx) =>
        sum(
          map(
            (value: any) => value[idx],
            path([rowIndex, "values"], resultRows)
          )
        ),
      events
    );

    return calculateFormula(formula.value, setVariables(totals));
  }
  return sum(map((i) => sum(i), values));
};

export const processConfig = (config: any) => {
  if (config) {
    if (typeof config === "object") {
      return config;
    } else {
      try {
        const parseConfig = JSON.parse(config);
        return parseConfig;
      } catch (e) {
        return {};
      }
    }
  }
  return {};
};
