/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";
import { useSpring, animated } from "react-spring";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  onClick?: () => void;
  style: {};
  isActive: boolean;
}

const Dashboard = forwardRef(
  (
    {
      width = 14,
      height = 14,
      className,
      onClick,
      fill,
      style,
      isActive,
      ...restProps
    }: IconProps,
    ref
  ) => {
    const rectStyles = useSpring({
      to: { fill: isActive ? "#229BFF" : "#949494" },
      config: { duration: 500 },
    });
    const rect2Styles = useSpring({
      to: { fill: isActive ? "#5AD3FF" : "#C9CDD4" },
      config: { duration: 500 },
    });
    return (
      <svg
        width={width}
        height={height}
        className={className}
        style={style}
        onClick={onClick}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <animated.rect
          y="0.00012207"
          width="5.41176"
          height="6.46154"
          rx="1"
          fill="#949494"
          style={rectStyles}
        />
        <animated.rect
          y="7.38489"
          width="5.41176"
          height="4.61538"
          rx="1"
          fill="#C9CDD4"
          style={rect2Styles}
        />
        <animated.rect
          x="6.58838"
          y="0.00012207"
          width="5.41176"
          height="3.69231"
          rx="1"
          fill="#C9CDD4"
          style={rect2Styles}
        />
        <animated.rect
          x="6.58838"
          y="4.61536"
          width="5.41176"
          height="7.38462"
          rx="1"
          fill="#949494"
          style={rectStyles}
        />
      </svg>
    );
  }
);

export default Dashboard;
