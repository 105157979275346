import {
  path,
  split,
  map,
  sum,
  join,
  groupBy,
  addIndex,
  forEachObjIndexed,
} from "ramda";
import dayjs from "dayjs";

import { formatMDhm, formatMonthDay } from "utils/dateUtil";
import { formatNumberWithUnit, numberWithUnit } from "utils/stringUtil";

export const calcHeightByNumTypes = (item: any) =>
  `calc(100% - ${
    (path(["numberTypes"], item) || []).length > 0 ? "35px" : "1px"
  })`;

export const dateFormat = (unit: string, time: string) => {
  let XName = "";
  switch (unit) {
    case "HOUR":
      XName = dayjs(time).format(formatMDhm);
      break;
    case "DAY":
      XName = time;
      break;
    case "WEEK":
      const dates = split("~", time);
      XName = `${dayjs(dates[0]).format(formatMonthDay)} ~ ${dayjs(
        dates[1],
      ).format(formatMonthDay)}`;
      break;
    case "MONTH":
      XName = time;
      break;
    default:
      XName = time;
  }
  return XName;
};

export const displayNameFormat = (
  groupBy: string,
  byValues: string | undefined,
  eventName: string,
  isPie?: boolean | undefined,
) => {
  const groupString = groupBy ? `${groupBy}: ` : "";
  const byValuesString = groupBy ? `${byValues || "unknow"} | ` : "";
  if (isPie) {
    return `${groupString}${byValues}`;
  }
  return `${byValuesString}${eventName}`;
};

const dotStyle = `width:5px;height:5px;border-radius:4px;margin-right: 5px;`;

const flexCss =
  "display:flex;justify-content: space-between;align-items: center;";

const renderTips = (groups: []) => {
  return join(
    "",
    addIndex(map)(
      (i: any, idx: number) =>
        path(["data", "hide"], i)
          ? ""
          : `<div style="${flexCss};line-height: 28px;"><div style="${flexCss}"><div style="${dotStyle}background:${
              i.color
            };"></div><div>${
              path(["data", "name"], i) || path(["data", "eventName"], i) || ""
            }</div></div><div style="margin-left:20px;font-weight:600;">${numberWithUnit(
              i.value,
              path(["data", "units"], i),
            )}</div></div>`,
      groups,
    ),
  );
};

const formatter = (
  { params, ticket }: { params: any; ticket: any },
  byGroups: any,
) => {
  let itemsNode: string = "";
  const date = `<div>${path([0, "axisValueLabel"], params)}</div>`;
  if ((byGroups || []).length > 0) {
    let groupTips: {}[] = [];
    const grouped = groupBy(
      (item: any) => path(["data", "eventName"], item),
      params || [],
    );

    const printKeyConcatValue = (value: any, key: any) => {
      groupTips.push({
        name: key,
        children: value,
      });
    };

    forEachObjIndexed(printKeyConcatValue, grouped);

    itemsNode = join(
      "",
      map(
        (i: any) =>
          `<div style="margin-bottom:5px;"><div style="font-weight:600;margin-bottom:10px;"><span>${
            i.name
          }</span><span style="margin-left:20px;">${numberWithUnit(
            sum(map((c) => c.value, i.children)),
            path([0, "data", "units"], i.children),
          )}</span></div>${renderTips(i.children)}</div>`,
        groupTips,
      ),
    );
  } else {
    itemsNode = renderTips(params);
  }

  return `<div style="padding:1px 20px;"><div style="margin-bottom:10px;font-weight:600;">${date}</div>${itemsNode}</div>`;
};

export const tooltip = (byGroups: any) => ({
  trigger: "axis",
  axisPointer: {
    type: "shadow",
  },
  transitionDuration: 1.5,
  enterable: true,
  className: "tooltips-widgets-inside",
  appendToBody: true,
  animation: false,
  formatter: (params: any, ticket: any) => {
    return formatter({ params: params, ticket: ticket }, byGroups);
  },
  position: function (point: any, params: any, dom: any, rect: any, size: any) {
    // Fix top
    // const obj: any = { top: 60 };
    // const vi: any = ["left", "right"][+(point[0] < size.viewSize[0] / 2)];
    // obj[vi] = 5;
    // return [point[0], "10%"];

    let x = 0;
    let y = 0;

    const pointX = point[0];
    const pointY = point[1];

    const boxWidth = size.contentSize[0];
    const boxHeight = size.contentSize[1];

    if (boxWidth > pointX) {
      x = pointX + 5;
    } else {
      x = pointX - boxWidth - 5;
    }

    if (boxHeight > pointY) {
      y = 5 + 28;
    } else {
      y = pointY - boxHeight;
    }

    return [x, y];
  },
});

export const yAxisLabelFormat = (value: any) => {
  return formatNumberWithUnit(value);
};
