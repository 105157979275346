/** @format */

import { addIndex, unnest, map } from "ramda";

import { calculateFormula, generateNumberToLetterMap } from "utils/stringUtil";

export const processRows = (rows: any, formulas: any = []) => {
  if (formulas.length > 0) {
    const customizedRows: any = map((i: any) => {
      return {
        byValues: i.byValues,
        values: map((value: any) => {
          return addIndex(map)((formula: any, index) => {
            const variables: any = {};
            addIndex(map)((num, numIdx) => {
              variables[generateNumberToLetterMap()[numIdx]] = num;
            }, value);
            let total = calculateFormula(formula.value, variables);
            return total;
          }, formulas);
        }, i.values),
      };
    }, rows);

    return customizedRows;
  }
  return rows;
};
