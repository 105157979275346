/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
  onClick?: () => void;
  className?: any;
}

const Column = forwardRef(
  (
    {
      width = 18,
      height = 18,
      fill,
      style,
      onClick,
      className,
      ...restProps
    }: IconProps,
    ref
  ) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...restProps}
    >
      <path d="M16.5535 14.6256H2.73207V2.08991C2.73207 2.00152 2.65975 1.9292 2.57136 1.9292H1.44636C1.35797 1.9292 1.28564 2.00152 1.28564 2.08991V15.9113C1.28564 15.9997 1.35797 16.0721 1.44636 16.0721H16.5535C16.6419 16.0721 16.7142 15.9997 16.7142 15.9113V14.7863C16.7142 14.698 16.6419 14.6256 16.5535 14.6256ZM4.49993 13.0185H5.62493C5.71332 13.0185 5.78565 12.9462 5.78565 12.8578V9.96491C5.78565 9.87652 5.71332 9.8042 5.62493 9.8042H4.49993C4.41154 9.8042 4.33922 9.87652 4.33922 9.96491V12.8578C4.33922 12.9462 4.41154 13.0185 4.49993 13.0185ZM7.5535 13.0185H8.6785C8.7669 13.0185 8.83922 12.9462 8.83922 12.8578V6.4292C8.83922 6.34081 8.7669 6.26849 8.6785 6.26849H7.5535C7.46511 6.26849 7.39279 6.34081 7.39279 6.4292V12.8578C7.39279 12.9462 7.46511 13.0185 7.5535 13.0185ZM10.6071 13.0185H11.7321C11.8205 13.0185 11.8928 12.9462 11.8928 12.8578V7.99616C11.8928 7.90777 11.8205 7.83545 11.7321 7.83545H10.6071C10.5187 7.83545 10.4464 7.90777 10.4464 7.99616V12.8578C10.4464 12.9462 10.5187 13.0185 10.6071 13.0185ZM13.6606 13.0185H14.7856C14.874 13.0185 14.9464 12.9462 14.9464 12.8578V4.82206C14.9464 4.73366 14.874 4.66134 14.7856 4.66134H13.6606C13.5723 4.66134 13.4999 4.73366 13.4999 4.82206V12.8578C13.4999 12.9462 13.5723 13.0185 13.6606 13.0185Z" />
    </svg>
  )
);

export default Column;
