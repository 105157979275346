/** @format */

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import styled from "styled-components";
import { range, addIndex, map, any, length, last, path, sum } from "ramda";

import AdaptiveWidget from "components/shared/elements/AdaptiveWidget";
import UpIcon from "assets/Up";
import DownIcon from "assets/Down";
import Hint from "components/shared/elements/Hint";
import {
  typesExist,
  roundFun,
  calculateFormula,
  generateNumberToLetterMap,
  tableSkeleton,
  formatNumberWithUnit,
} from "utils/stringUtil";
import { GetDateMonthDayString } from "utils/dateUtil";
import { formatValueByFormula } from "utils/widgetHelper";
import { deleteColor, redColor } from "constants/colors";

import Value from "./Value";

interface ContentProps {
  isVisiableFooter: boolean;
  isSmall: boolean;
}

const StyledContainer = styled(AdaptiveWidget)`
  background: #fff;
  width: 100%;
  height: ${(props) => `calc(100% - ${props.headerHeight || 0}px)`};
  border-radius: 4px;
`;

const StyledContent = styled.div<{ isVisiableFooter: any; isSmall: any }>`
  padding: 0;
  height: ${(props) => (props.isVisiableFooter ? "calc(100% - 10px)" : "100%")};
  display: ${(props) => (props.isSmall ? "grid" : "flex")};
  grid-template-columns: 66% 33%;
  align-items: ${(props) => (props.isSmall ? "end" : "center")};
`;

const StyledContentLeft = styled.div`
  padding-right: 5px;
`;

const StyledContentRight = styled.div`
  border-left: 1px solid #c9cdd4;
  padding-left: 8px;
`;

const StyledNum = styled.span`
  font-size: 55px;
  display: inline-block;
  max-width: calc(100% - 45px);
  white-space: nowrap;
  font-weight: 500;
  // transition: 0.1s font-size;
`;

const StyledUnit = styled.span`
  margin-left: 12px;
  display: inline-block;
  width: 30px;
  line-height: 22px;
`;

const StyledFooter = styled.div`
  height: 16px;
  line-height: 16px;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  padding: 0;
`;

const StyledItem = styled.div`
  display: flex;
  flex: 1;
  width: 98%;
`;

const StyledComparison = styled.div`
  display: flex;
  align-items: center;
`;

const StyledComparisonAsM = styled(StyledComparison)`
  width: 50%; // calc(100% - 64px);
`;

const StyledComparisonAsY = styled(StyledComparison)`
  width: 50%; //calc(100% - 85px);
`;

const IconStyle = { verticalAlign: "-1px" };

const fontColor = (num: number) => {
  if (num > 0) {
    return deleteColor;
  }

  if (num < 0) {
    return redColor;
  }
  return "#949494";
};

const setVariables = (values: any) => {
  const variables: any = {};
  addIndex(map)((num, numIdx) => {
    variables[generateNumberToLetterMap()[numIdx]] = num;
  }, values || []);

  return variables;
};

const Digital = ({
  item,
  data,
  ratioData,
  name,
  headerHeight,
}: {
  item?: any;
  data?: any;
  ratioData?: any;
  name?: string;
  headerHeight?: number;
}) => {
  const itemRef: any = useRef(null);
  const numRef: any = useRef(null);
  const [allValues, setAllValues] = useState<number>(0);
  const [allValuesEveryday, setAllValuesEveryday] = useState<number>(0);

  const widgetInfo = useMemo(() => {
    return path(["widget"], item);
  }, [item]);

  const layout = useMemo(() => {
    return path(["layout"], item) || [];
  }, [item]);

  const numberTypes = path(["numberTypes"], widgetInfo) || [];

  const analysis = path(["analysis"], widgetInfo);

  const memoFormulas = useMemo(() => {
    return path(["formulas"], analysis) || [];
  }, [analysis]);

  const formula = path([0], memoFormulas);

  const [itemSize, setItemSize] = useState<{
    width: number;
    height: number;
    headerHeight?: number;
  }>({
    width: 1,
    height: 1,
  });
  const [fontSize, setFontSize] = useState<number>(58);
  const [currentNumber, setCurrentNumber] = useState<any>(null);
  const [layoutCache, setLayoutCache] = useState<any>(null);

  useEffect(() => {
    if (JSON.stringify(layoutCache) !== JSON.stringify(layout)) {
      setCurrentNumber(null);
      setLayoutCache(layout);
    }
  }, [layout, layoutCache]);

  useEffect(() => {
    const rows: {}[] = path(["details"], data) || [];
    const eventDisplayNames: string[] = path(["indicatorNames"], data) || [];

    let sumValues: any = sum(
      map((i) => {
        return sum(map((j: any) => sum(j), path(["values"], i)));
      }, rows),
    );

    let sumValuesEveryday: any = sum(
      map((i) => {
        return sum(
          map(
            (j: any) => {
              if (memoFormulas.length > 0) {
                const formula = memoFormulas[0];
                let total = calculateFormula(formula.value, setVariables(j));
                return total;
              }
              return sum(j);
            },
            path(["values"], i),
          ),
        );
      }, rows),
    );

    if (memoFormulas.length > 0) {
      const formula = memoFormulas[0];
      const totals = addIndex(map)(
        (i, idx) =>
          sum(
            map(
              (row: any) => sum(map((value) => value[idx], row.values)),
              rows,
            ),
          ),
        eventDisplayNames,
      );
      sumValues = calculateFormula(formula.value, setVariables(totals));
    }

    setAllValuesEveryday(sumValuesEveryday);
    setAllValues(sumValues);
  }, [setAllValues, memoFormulas, data]);

  const momRate = path(["momRate"], ratioData) || 0;
  const yoyRate = path(["yoyRate"], ratioData) || 0;

  const unit: string = path(["analysis", "unit"], widgetInfo);

  const isSmall: boolean = path(["layout", "w"], item) === 1;
  const series = path(["dates"], data) || ([] as any);
  const seriesLength = series.length;
  const lastDate = last(path(["dates"], data) || []);
  const units = path(["units"], data) || [];
  const lastValue = last(path(["details", 0, "values"], data) || []);

  const lastNum =
    memoFormulas.length > 0
      ? calculateFormula(memoFormulas[0].value, setVariables(lastValue))
      : lastValue || 0;

  const average = seriesLength ? allValuesEveryday / seriesLength : 0;
  const isVisiableFooter: boolean = true;
  const lastNumLineHeightStyle =
    fontSize > 45 && isVisiableFooter ? { lineHeight: `${fontSize}px` } : {};

  const currentValueNumber = formatValueByFormula(lastNum, formula);
  const totalValueNumber = formatValueByFormula(allValues, formula);
  const averageValueNumber = formatValueByFormula(average, formula);

  useEffect(() => {
    if (
      itemRef.current &&
      numRef.current &&
      currentValueNumber !== currentNumber
    ) {
      const numWithUnit = formatNumberWithUnit(currentValueNumber);
      const width = itemRef.current.clientWidth;
      const sumValue: any = numWithUnit.toString();

      let size = ((width - 45) * 1.22) / sumValue.length;
      size = size > 58 ? 58 : size;
      setFontSize(size);
      setCurrentNumber(currentValueNumber);
    }
  }, [itemRef, numRef, currentValueNumber, currentNumber, itemSize]);

  const iconArrow = (num: number) => (
    <div style={{ width: num !== 0 ? "7px" : 0, marginRight: "4px" }}>
      {num > 0 && <UpIcon style={IconStyle} />}
      {num < 0 && <DownIcon style={IconStyle} />}
    </div>
  );

  const ratioNumStyle: { width?: string | undefined } = {};

  if (!isSmall) {
    ratioNumStyle.width = "100%";
  }

  const ringRate = (
    <StyledItem>
      <div
        style={{
          width: isSmall ? "48%" : "98%",
          maxWidth: "65px",
          color: "#949494",
          paddingRight: "2%",
        }}
      >
        <Hint content="Ring-Rate" />
      </div>
      <StyledComparisonAsM
        style={{
          color: fontColor(momRate),
          ...ratioNumStyle,
        }}
      >
        {iconArrow(momRate)}
        <Hint content={momRate ? `${roundFun(momRate, 2)}%` : tableSkeleton} />
      </StyledComparisonAsM>
    </StyledItem>
  );

  const yoy = (
    <StyledItem>
      <div
        style={{
          width: isSmall ? "48%" : "98%",
          paddingRight: "2%",
          maxWidth: "85px",
          color: "#949494",
        }}
      >
        <Hint content="Year-on-Year" />
      </div>
      <StyledComparisonAsY
        style={{ color: fontColor(yoyRate), ...ratioNumStyle }}
      >
        {iconArrow(yoyRate)}
        <Hint content={yoyRate ? `${roundFun(yoyRate, 2)}%` : tableSkeleton} />
      </StyledComparisonAsY>
    </StyledItem>
  );

  return (
    <StyledContainer setItemSize={setItemSize} headerHeight={headerHeight}>
      <StyledContent
        ref={itemRef}
        isSmall={isSmall}
        isVisiableFooter={isVisiableFooter}
        style={{}}
      >
        <StyledContentLeft>
          <div>
            <Hint
              content={
                unit === "WEEK" ? lastDate : GetDateMonthDayString(lastDate)
              }
            />
          </div>
          <div style={{ display: "flex", alignItems: "end" }}>
            <StyledNum
              style={{
                fontSize: `${fontSize}px`,
                ...lastNumLineHeightStyle,
              }}
              ref={numRef}
            >
              {currentValueNumber}
            </StyledNum>
            <StyledUnit></StyledUnit>
          </div>
          {!isSmall &&
            (typesExist("MOM", numberTypes) ||
              typesExist("YOY", numberTypes)) && (
              <div
                style={{
                  paddingRight: "8px",
                  display: "flex",
                }}
              >
                {typesExist("MOM", numberTypes) && (
                  <div
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    {ringRate}
                  </div>
                )}
                {typesExist("YOY", numberTypes) && <div>{yoy}</div>}
              </div>
            )}
        </StyledContentLeft>

        <StyledContentRight>
          {typesExist("SUM", numberTypes) && (
            <Value
              layout={isSmall ? "vertical" : ""}
              labelStyle={{ display: "block", lineHeight: "22px" }}
              valueStyle={{ marginLeft: "0" }}
              item={{
                label: "Total",
                value: totalValueNumber,
                unit:
                  formula && path(["format"], formula) === "PERCENTAGE"
                    ? "%"
                    : "",
              }}
            />
          )}
          {typesExist("AVERAGE", numberTypes) && (
            <Value
              style={{
                marginTop:
                  typesExist("SUM", numberTypes) &&
                  !(isSmall && isVisiableFooter) &&
                  "10px",
              }}
              labelStyle={{ display: "block", lineHeight: "22px" }}
              valueStyle={{ marginLeft: "0" }}
              layout={isSmall ? "vertical" : ""}
              item={{
                label: "Average",
                value: averageValueNumber,
                unit:
                  formula && path(["format"], formula) === "PERCENTAGE"
                    ? "%"
                    : "",
              }}
            />
          )}
        </StyledContentRight>
      </StyledContent>
      {isVisiableFooter && isSmall && (
        <StyledFooter style={{ paddingTop: isSmall ? "5px" : 0 }}>
          {typesExist("MOM", numberTypes) && ringRate}
          {typesExist("YOY", numberTypes) && yoy}
        </StyledFooter>
      )}
    </StyledContainer>
  );
};

export default Digital;
