/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface CollapseIconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  onClick?: () => void;
  style?: {};
}

const Collapse = forwardRef(
  (
    {
      width = 16,
      height = 16,
      className,
      onClick,
      fill,
      style,
      ...restProps
    }: CollapseIconProps,
    ref
  ) => (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.33333H2V3H14V4.33333ZM14 8.66667L7.33333 8.66667V7.33333L14 7.33333V8.66667ZM2 13L14 13V11.6667L2 11.6667V13ZM4.70533 5.9995C4.92712 5.85599 5.21975 6.01519 5.21975 6.27936V9.5834C5.21975 9.84757 4.92712 10.0068 4.70533 9.86326L2.15225 8.21124C1.94925 8.07988 1.94925 7.78288 2.15225 7.65152L4.70533 5.9995Z"
        fill="#4F4F4F"
      />
    </svg>
  )
);

export default Collapse;
