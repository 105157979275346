/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

import type { CheckboxValueType } from "antd/es/checkbox/Group";

interface BooleanOptions {
  label: string;
  value: boolean;
}

export const deleteColor = "#F53F3F";
export const redColor = "#00b42a";
export const greyColor = "#C9CDD4";
export const tooltipBgColor = "#949494";

export const colors = [
  "#5BBEF0",
  "#76BD93",
  "#E5B682",
  "#8AE1B9",
  "#46C2CB",
  "#749AE4",
  "#FF8787",
  "#F8C4B4",
  "#A5CCDA",
  "#8A7FDE",
  "#EEB76B",
  "#BCBF56",
  "#FFDCA9",
  "#FAAB78",
  "#EBC860",
  "#B8D589",
  "#98A8F8",
  "#D989B5",
  "#DE9A9A",
  "#BA94D1",
  "#BCCEF8",
  "#7CCEB0",
  "#C8DBBE",
  "#D0B8A8",
];

export const echartsColors = [
  "#4080FF",
  "#00B3FF",
  "#6DA4FA",
  "#68DDB5",
  "#7084A3",
  "#F9C719",
  "#F17B62",
  "#7DD2F0",
  "#A677C6",
  "#FFA956",
  "#1DA3A4",
  "#FFADCE",
  "#D0B8A8",
  "#BDD2FD",
  "#BDEFDB",
  "#C2C8D5",
  "#FBE5A2",
  "#D3CEFD",
  "#B6E3F5",
  "#D3C6EA",
  "#FFD8B8",
  "#AAD8D8",
  "#FFD6E7",
];

export const color = (index: number) =>
  echartsColors[index % echartsColors.length];
