/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";
import { useSpring, animated } from "react-spring";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  onClick?: () => void;
  style: {};
  isActive: boolean;
}

const Environment = forwardRef(
  (
    {
      width = 15,
      height = 14,
      className,
      onClick,
      fill,
      style,
      isActive,
      ...restProps
    }: IconProps,
    ref
  ) => {
    const pathStyles = useSpring({
      to: { fill: isActive ? "#229BFF" : "#949494" },
      config: { duration: 500 },
    });
    const path2Styles = useSpring({
      to: { fill: isActive ? "#5AD3FF" : "#C9CDD4" },
      config: { duration: 500 },
    });

    return (
      <svg
        width={width}
        height={height}
        className={className}
        style={style}
        onClick={onClick}
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <animated.path
          d="M5.5 0L10.5 2.75V8.25L5.5 11L0.5 8.25V2.75L5.5 0Z"
          fill="#949494"
          style={pathStyles}
        />
        <g style={{ mixBlendMode: "multiply" }}>
          <animated.path
            d="M10.5334 5.0238L13.5334 6.75585V10.22L10.5334 11.952L7.53345 10.22V6.75585L10.5334 5.0238Z"
            fill="#C9CDD4"
            style={path2Styles}
          />
        </g>
      </svg>
    );
  }
);

export default Environment;
