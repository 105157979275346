import {
  initReactI18next,
  useTranslation as usetranslation,
} from "react-i18next";
import i18n from "i18next";
import zh from "./models/zh_CN.json";
import en from "./models/en.json";
import { keys } from "ramda";
interface IResource {
  [language: string]: typeof en;
}
const resource: IResource = {
  en: en,
  "zh-CN": zh,
};
window.addEventListener("languagechange", () => {
  i18n.changeLanguage("en");
  //i18n.changeLanguage(navigator.language);
});
i18n.use(initReactI18next).init({
  resources: resource,
  ns: keys(en),
  lng: "en",
  //lng: navigator.language,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export const useTranslation = usetranslation<keyof typeof en>;
