/** @format */

import React, { lazy, useState } from "react";
import styled from "styled-components";
import { Route, Routes, Navigate, useParams } from "react-router-dom";

import ErrorBoundary from "components/shared/elements/ErrorBoundary";
import SideBar from "components/Main/SideBar";
import ProjectSettings from "./ProjectSettings";
import Events from "./Events";
import { routerName } from "utils/constants";

const Dashboards = lazy(() => import("./Dashboards"));
const Analysis = lazy(() => import("./Analysis"));
const Environments = lazy(() => import("./Environments"));

const StyledContainter = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  position: absolute;
  background: #f2f3f5;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 100%;
  grid-column-gap: 20px;
`;

const StyledRight = styled.div`
  overflow: hidden;
`;

const Insights = () => {
  const [isStart, setStart] = useState(false);
  const params = useParams();
  const { projectId } = params;

  return (
    <StyledContainter>
      <SideBar isStart={isStart} />
      <StyledRight>
        <ErrorBoundary setStart={setStart}>
          <Routes>
            <Route
              path={`/*`}
              element={
                <Navigate
                  replace
                  to={`/${routerName}/${projectId}/dashboards`}
                />
              }
            />
            <Route path="/dashboards/*" element={<Dashboards />} />
            <Route path="/analysis/*" element={<Analysis />} />
            <Route path="/events/*" element={<Events />} />
            <Route path="/environments" element={<Environments />} />
            <Route path="/project-settings/*" element={<ProjectSettings />} />
          </Routes>
        </ErrorBoundary>
      </StyledRight>
    </StyledContainter>
  );
};

export default Insights;
