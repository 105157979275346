/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { map, join, addIndex, indexOf } from "ramda";

import EmptyBox from "assets/EmptyBox";

const Container = styled.div``;

const StyledHeader = styled.div`
  background: #fafafa;
`;

const StyledHeaderRow = styled.div`
  border-radius: 4px;
  display: grid;
  border-bottom: 1px solid #f2f3f5;
`;

const StyledHeaderItems = styled.div<{ align: string }>`
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  word-wrap: break-word;
  text-align: ${(props) => props.align || "left"};
`;

const StyledBody = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  overflow-y: auto;
`;

const StyledBodyRow = styled.div`
  display: grid;
  border-bottom: 1px solid #f2f3f5;
  &:hover {
    background: #fcfdfd;
  }
`;

const StyledBodyItems = styled.div<{ align: string }>`
  padding: 10px;
  font-size: 14px;
  /*word-wrap: break-word;*/
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${(props) => props.align || "left"};
`;

const StyledColButton = styled.span`
  margin-right: 10px;
  cursor: pointer;
  transition: 0.5s transform;
`;

const setWitdhValue = (width: any) =>
  indexOf("%", width) > -1
    ? `${parseInt(width, 10) / 10}fr`
    : `${parseInt(width, 10)}px`;

const TableBodyRow = ({
  item,
  columns,
  lineNum,
  gridTemplateColumns,
}: {
  item: any;
  columns: any;
  lineNum: number;
  gridTemplateColumns: string;
}) => {
  const columnList: any = addIndex(map)((column: any, idx) => {
    const content = column.render
      ? column.render(item[column.dataIndex], item, lineNum)
      : item[column.dataIndex];
    return (
      <StyledBodyItems key={column.dataIndex} align={column.align}>
        {content}
      </StyledBodyItems>
    );
  }, columns);
  return (
    <>
      <StyledBodyRow style={{ gridTemplateColumns }}>
        {columnList}
      </StyledBodyRow>
    </>
  );
};

const ItemList = ({
  dataSource,
  height = "auto",
  style = {},
  headerStyle = {},
  bodyStyle = {},
  inBodyStyle = {},
  hideHeader = false,
  columns,
  isScroll = false,
}: {
  dataSource: any;
  height?: any;
  style?: {};
  headerStyle?: {};
  bodyStyle?: {};
  inBodyStyle?: {};
  hideHeader?: boolean;
  columns: any;
  isScroll?: boolean;
}) => {
  const bodyEl = useRef<any>(null);

  const widths = map(
    (i) =>
      i.width
        ? setWitdhValue(i.width)
        : `minmax(${i.minWidth ? parseInt(i.minWidth, 10) : 20}px, 1fr)`, // `minmax(${parseInt(i.width, 0)}px, 1fr)` : '1fr'
    columns,
  );

  const gridTemplateColumns = join(" ", widths);

  const bodyContent = (
    <>
      {dataSource.length < 1 && (
        <div
          style={{
            height: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EmptyBox width={128} height={90} />
        </div>
      )}
      {addIndex(map)(
        (item: any, lineNum) => (
          <div key={`${item.id}_${lineNum}`}>
            <TableBodyRow
              columns={columns}
              item={item}
              lineNum={lineNum}
              gridTemplateColumns={gridTemplateColumns}
            />
          </div>
        ),
        dataSource || [],
      )}
    </>
  );

  return (
    <Container style={style}>
      {!hideHeader && (
        <StyledHeader
          style={{
            paddingRight:
              (bodyEl.current && bodyEl.current.scrollHeight > height) ||
              (isScroll && "9px"),
            ...headerStyle,
          }}
        >
          <StyledHeaderRow style={{ gridTemplateColumns }}>
            {map(
              (column) => (
                <StyledHeaderItems key={column.dataIndex} align={column.align}>
                  {column.title}
                </StyledHeaderItems>
              ),
              columns,
            )}
          </StyledHeaderRow>
        </StyledHeader>
      )}
      <StyledBody
        style={{ height: `${(height * 10) / 10}px`, ...bodyStyle }}
        ref={bodyEl}
      >
        {isScroll ? <div style={inBodyStyle}>{bodyContent}</div> : bodyContent}
      </StyledBody>
    </Container>
  );
};

export default ItemList;
