import { Select } from "antd";
import { useParams } from "react-router-dom";
import {
  path,
  map,
  any,
  join,
  sum,
  addIndex,
  toUpper,
  toLower,
  filter,
} from "ramda";
import React, { useMemo, useState, useContext, useEffect, memo } from "react";
import { FC } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import AdaptiveWidget from "components/shared/elements/AdaptiveWidget";
import { retentionCalculateByOptions } from "constants/options";
import Table from "./Table";

dayjs.extend(utc);

const StyledContainer = styled(AdaptiveWidget)`
  height: ${(props) => `calc(100% - ${props.headerHeight || 0}px)`};
`;

const StyledHeader = styled.div``;

interface RetentionTableProps {
  item: any;
  data: any;
  headerHeight?: number;
  nodeRef: any;
  fetchQuery: any;
  setData?: any;
  chartFilter?: any;
  setChartFilter?: any;
}

const RetentionTable: FC<RetentionTableProps> = memo(
  ({
    item,
    data,
    headerHeight = 160,
    nodeRef,
    chartFilter,
    setChartFilter,
    fetchQuery,
    setData,
  }) => {
    const [itemSize, setItemSize] = useState<{ width: number; height: number }>(
      {
        width: 1,
        height: 1,
      },
    );

    const widgetInfo = useMemo(() => {
      return path(["widget"], item);
    }, [item]);

    const calculateBy = useMemo(() => {
      return path(["analysis", "calculateBy"], widgetInfo);
    }, [widgetInfo]);

    const onChangeCalculateBy = (v: string) => {
      fetchQuery({
        ...widgetInfo,
        analysis: { ...widgetInfo.analysis, calculateBy: v },
      });
      setChartFilter({ calculateBy: v });
      setData(null);
    };

    return (
      <StyledContainer setItemSize={setItemSize} headerHeight={headerHeight}>
        <StyledHeader>
          <Select
            value={path(["calculateBy"], chartFilter) || calculateBy}
            style={{ width: 120 }}
            onChange={onChangeCalculateBy}
            size="small"
            getPopupContainer={() => nodeRef.current || document.body}
            options={retentionCalculateByOptions}
          />
        </StyledHeader>
        <Table
          analysis={widgetInfo.analysis}
          itemSize={{ ...itemSize, height: itemSize.height - 25 }}
          retentionResult={data}
          pagination={{ pageSize: 50 }}
        />
      </StyledContainer>
    );
  },
);

export default RetentionTable;
