/** @format */

import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { range, map, path, sum, join } from "ramda";
import * as echarts from "echarts/core";
import {
  DatasetComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  LegendComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

import AdaptiveWidget from "components/shared/elements/AdaptiveWidget";
import { echartsColors } from "constants/colors";

import DisplayTotal from "./DisplayTotal";
import {
  calcHeightByNumTypes,
  dateFormat,
  tooltip,
  yAxisLabelFormat,
} from "./Helper";
import { lineData } from "./DataHelper";

const StyledContainer = styled(AdaptiveWidget)`
  background: #fff;
  width: 100%;
  height: ${(props) => `calc(100% - ${props.headerHeight || 0}px)`};
  border-radius: 4px;
`;

const seriesBase = {
  type: "line",
  symbol: "roundRect",
  connectNulls: true,
  smooth: false,
};

const Line = ({
  valueUnit,
  item,
  data,
  headerHeight,
  showDataZoom,
  legend,
  grid,
}: {
  valueUnit?: any;
  item?: any;
  data?: any;
  headerHeight?: number;
  showDataZoom?: boolean;
  legend?: any;
  grid?: any;
}) => {
  const mapRef: any = useRef(null);
  const [itemSize, setItemSize] = useState<{ width: number; height: number }>({
    width: 1,
    height: 1,
  });
  const [initChart, setInitChart] = useState<any>();
  const [height, setHeight] = useState<string>();
  const [total, setTotal] = useState<number>(0);

  const widgetInfo = useMemo(() => {
    return path(["widget"], item);
  }, [item]);

  const [series, xAxisLabels, groupBys] = lineData(
    data,
    path(["analysis", "formulas"], widgetInfo),
  );

  echarts.use([
    DatasetComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    DataZoomComponent,
    UniversalTransition,
  ]);

  useEffect(() => {
    const myChart = echarts.init(mapRef.current);
    setInitChart(myChart);
  }, []);

  useEffect(() => {
    if (initChart) {
      initChart.resize();
    }
  }, [initChart, itemSize, height]);

  useEffect(() => {
    const resizeHandler = () => {
      if (initChart) {
        initChart.resize();
      }
    };
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
      if (initChart) {
        initChart.dispose();
      }
    };
  }, [initChart]);

  useEffect(() => {
    setHeight(calcHeightByNumTypes(widgetInfo));
  }, [widgetInfo]);

  useEffect(() => {
    const rowsSum = sum(map((i: any) => sum(path(["data"], i)), series));
    setTotal(rowsSum);
  }, [series]);

  useEffect(() => {
    const unit = path(["analysis", "unit"], widgetInfo);

    const lineSeries = map(
      (i) => ({ ...i, ...seriesBase, showSymbol: false }),
      series,
    );

    const optionsProps: any = {};

    if (showDataZoom) {
      optionsProps.dataZoom = [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
      ];
    }

    const option = {
      tooltip: {
        ...tooltip(groupBys),
      },
      legend: { type: "scroll", bottom: showDataZoom ? 45 : 5, ...legend },
      color: echartsColors,
      grid: {
        left: "7%",
        right: "8%",
        bottom: showDataZoom ? "25%" : "15%",
        top: "8%",
        containLabel: true,
        ...grid,
      },
      toolbox: {
        feature: {},
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: xAxisLabels,
        axisLine: {
          lineStyle: { color: "#E5E6EB" },
        },
        axisLabel: {
          color: "#86909C",
          // rotate: 60,
          formatter: function (value: any) {
            return dateFormat(unit, value);
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: yAxisLabelFormat,
        },
      },
      series: lineSeries,
      ...optionsProps,
    };

    if (initChart) {
      initChart.setOption(option, true);
    }
  }, [
    initChart,
    legend,
    xAxisLabels,
    groupBys,
    series,
    grid,
    widgetInfo,
    showDataZoom,
  ]);

  return (
    <StyledContainer setItemSize={setItemSize} headerHeight={headerHeight}>
      <DisplayTotal
        item={widgetInfo}
        average={(total / (xAxisLabels || []).length).toFixed(2)}
        total={total}
        data={data}
      />
      <div ref={mapRef} style={{ height: height }} className="nonDraggable" />
    </StyledContainer>
  );
};

export default Line;
