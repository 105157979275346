/** @format */

export interface TableStateProps {
  tables: any;
  updateTableSort: (
    projectId: string | undefined,
    tableName: string,
    sort: [],
  ) => void;
  updateTableSize: (
    projectId: string | undefined,
    tableName: string,
    size: [],
  ) => void;
  updateTablePage: (
    projectId: string | undefined,
    tableName: string,
    page: { pageIndex: number; pageSize: number },
  ) => void;
  clearTableConfiguration: (projectId: any, tableName: string) => void;
}

const updateTable = (
  projectId: string,
  tableName: string,
  table: any,
  option: any,
) => {
  const currentProjectTableData: any = {};
  const oldProjectTableData = table[projectId];
  if (oldProjectTableData) {
    currentProjectTableData[projectId] = {
      ...oldProjectTableData,
      ...option,
    };
  } else {
    currentProjectTableData[projectId] = option;
  }
  const currentTable: any = {};
  currentTable[tableName] = { ...table, ...currentProjectTableData };
  return currentTable;
};

export const tableState = (set: any, get: any) => {
  return {
    tables: { customQuery: null },
    updateTableSize: (projectId: any, tableName: string, size: []) => {
      const allTables = get().tables || {};
      const table = get().tables[tableName] || {};
      console.log(size);
      const currentTable = updateTable(projectId, tableName, table, {
        size: size,
      });
      set({ tables: { ...allTables, ...currentTable } });
    },
    updateTableSort: (projectId: any, tableName: string, sort: []) => {
      const allTables = get().tables || {};
      const table = get().tables[tableName] || {};
      const currentTable = updateTable(projectId, tableName, table, {
        sort: sort,
      });

      console.log(currentTable);

      set({ tables: { ...allTables, ...currentTable } });
    },
    updateTablePage: (
      projectId: any,
      tableName: string,
      page: { pageIndex: number; pageSize: number },
    ) => {
      const allTables = get().tables || {};
      const table = get().tables[tableName] || {};
      const currentTable = updateTable(projectId, tableName, table, {
        page: page,
      });

      console.log(currentTable);

      set({ tables: { ...allTables, ...currentTable } });
    },
    clearTableConfiguration: (
      projectId: any,
      tableName: string,
      value?: boolean,
    ) => {
      const allTables = get().tables || {};
      const newTables = { ...allTables };
      newTables[tableName][projectId] = { clearTable: value };

      set({ tables: newTables });
    },
  };
};
