/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
  onClick?: () => void;
  className?: any;
}

const Line = forwardRef(
  (
    {
      width = 18,
      height = 18,
      fill,
      style,
      onClick,
      className,
      ...restProps
    }: IconProps,
    ref
  ) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...restProps}
    >
      <path d="M16.5535 14.6266H2.73207V2.09089C2.73207 2.0025 2.65975 1.93018 2.57136 1.93018H1.44636C1.35797 1.93018 1.28564 2.0025 1.28564 2.09089V15.9123C1.28564 16.0007 1.35797 16.073 1.44636 16.073H16.5535C16.6419 16.073 16.7142 16.0007 16.7142 15.9123V14.7873C16.7142 14.6989 16.6419 14.6266 16.5535 14.6266ZM4.85752 11.5268C4.9198 11.5891 5.02024 11.5891 5.08453 11.5268L7.86288 8.76254L10.4263 11.342C10.4885 11.4043 10.591 11.4043 10.6533 11.342L16.1859 5.81143C16.2481 5.74915 16.2481 5.64669 16.1859 5.58442L15.3903 4.78888C15.3601 4.75897 15.3193 4.74219 15.2768 4.74219C15.2343 4.74219 15.1935 4.75897 15.1633 4.78888L10.5428 9.40741L7.98341 6.83196C7.95321 6.80205 7.91242 6.78527 7.86991 6.78527C7.8274 6.78527 7.78661 6.80205 7.7564 6.83196L4.06399 10.5023C4.03408 10.5325 4.01731 10.5733 4.01731 10.6158C4.01731 10.6583 4.03408 10.6991 4.06399 10.7293L4.85752 11.5268Z" />
    </svg>
  )
);

export default Line;
