import React, { Component, Suspense } from "react";
import NProgress from "components/shared/elements/NProgress";
import { Button, Result } from "antd";
import styled from "styled-components";
import { path } from "ramda";
import { SmileOutlined } from "@ant-design/icons";
// import { unstable_batchedUpdates } from "react-dom";

import { deleteColor } from "constants/colors";

const StyledErrorMsg = styled.div`
  text-align: center;
  color: ${deleteColor};
`;

class ErrorBoundary extends Component<
  { setStart?: any; children: any },
  { hasError?: boolean; errorMsg: string | undefined; retryCount: number }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorMsg: "", retryCount: 0 };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const reloadCount = window.localStorage.getItem("reload");
    if (
      /Loading chunk [\d]+ failed/.test(path(["message"], error)) &&
      reloadCount !== "1"
    ) {
      window.localStorage.setItem("reload", "1");
      window.location.reload();
    } else {
      this.setState((state) => {
        return { errorMsg: error };
      });
      window.localStorage.setItem("reload", "0");
    }

    // some code here...
  }

  reloadJs() {
    this.setState((state) => {
      return { hasError: false };
    });
  }

  render() {
    const { hasError, errorMsg } = this.state;

    const msg: any = path(["message"], errorMsg);
    const {
      setStart,
      children,
      fallback = msg ? (
        <>
          <Result
            icon={<SmileOutlined />}
            title="Something went wrong!"
            extra={
              <Button type="primary" onClick={this.reloadJs.bind(this)}>
                reload
              </Button>
            }
          />
          <StyledErrorMsg>{msg}</StyledErrorMsg>
        </>
      ) : (
        <></>
      ),
    }: any = this.props;

    return hasError ? (
      fallback
    ) : (
      <Suspense fallback={<NProgress setStart={setStart} />}>
        {children}
      </Suspense>
    );
  }
}

export default ErrorBoundary;
