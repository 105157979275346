/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
  onClick?: () => void;
  className?: any;
}

const Pie = forwardRef(
  (
    {
      width = 18,
      height = 18,
      fill,
      style,
      onClick,
      className,
      ...restProps
    }: IconProps,
    ref
  ) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...restProps}
    >
      <g clipPath="url(#clip0_3415_148098)">
        <path d="M16.0714 9.11961H8.87941V1.92765C8.87941 1.83925 8.80709 1.76693 8.71869 1.76693H8.19637C7.14108 1.7652 6.09586 1.97216 5.12085 2.37588C4.14584 2.77961 3.26028 3.37214 2.51512 4.11939C1.78128 4.85096 1.19656 5.71815 0.793469 6.67274C0.374016 7.66281 0.158778 8.72739 0.160657 9.80265C0.158929 10.8579 0.365881 11.9032 0.769607 12.8782C1.17333 13.8532 1.76587 14.7387 2.51311 15.4839C3.25039 16.2212 4.11021 16.8018 5.06646 17.2056C6.05654 17.625 7.12111 17.8402 8.19637 17.8384C9.25166 17.8401 10.2969 17.6331 11.2719 17.2294C12.2469 16.8257 13.1325 16.2332 13.8776 15.4859C14.6149 14.7486 15.1955 13.8888 15.5993 12.9326C16.0187 11.9425 16.234 10.8779 16.2321 9.80265V9.28033C16.2321 9.19193 16.1598 9.11961 16.0714 9.11961ZM12.8912 14.5397C12.2692 15.1569 11.5314 15.6453 10.7203 15.977C9.90914 16.3086 9.04053 16.4769 8.16423 16.4723C6.39436 16.4643 4.73097 15.7712 3.47941 14.5196C2.21981 13.26 1.52673 11.5846 1.52673 9.80265C1.52673 8.02073 2.21981 6.34528 3.47941 5.08568C4.57628 3.98881 5.98856 3.31983 7.51334 3.16716V10.4857H14.8319C14.6772 12.0185 14.0022 13.4388 12.8912 14.5397ZM17.8392 8.00265L17.787 7.43613C17.6162 5.58591 16.7946 3.84015 15.4727 2.52229C14.1499 1.20201 12.4077 0.384921 10.5468 0.212022L9.97829 0.15979C9.88387 0.151754 9.80351 0.224076 9.80351 0.318495V8.03479C9.80351 8.12318 9.87584 8.1955 9.96423 8.1955L17.6785 8.17542C17.7729 8.17542 17.8473 8.09506 17.8392 8.00265ZM11.1656 6.83345V1.67854C12.4311 1.94341 13.5924 2.57014 14.5084 3.48256C15.4265 4.39863 16.0553 5.56381 16.3165 6.81939L11.1656 6.83345Z" />
      </g>
      <defs>
        <clipPath id="clip0_3415_148098">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  )
);

export default Pie;
