/** @format */
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import Hint from "components/shared/elements/Hint";
import { roundFun } from "utils/stringUtil";

const StyleLableUnit = styled.span`
  color: #949494;
  font-size: 12px;
`;

const StyleValue = styled.span`
  color: #4f4f4f;
  font-size: 20px;
  line-height: 20px;
  margin: 0 4px;
  font-weight: 500;
`;

interface ValueProps {
  item?: {
    label: string;
    value: string | number;
    unit: string;
  };
  layout?: string | undefined;
  style?: {};
  labelStyle?: {};
  valueStyle?: {};
}

const Value: FC<ValueProps> = ({
  item = { label: "", value: "", unit: "" },
  layout,
  style = {},
  labelStyle = {},
  valueStyle = {},
}) => {
  const value = useMemo(() => {
    return item.value; //roundFun(item.value, 5) || 0;
  }, [item]);

  return (
    <div style={style}>
      {layout === "vertical" ? (
        <>
          <StyleLableUnit
            style={{
              display: layout === "vertical" ? "block" : "inline-block",
            }}
          >
            {item.label}
          </StyleLableUnit>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "62% 35%",
              alignItems: "end",
            }}
          >
            <StyleValue
              style={{ marginLeft: 0, display: "flex", alignItems: "end" }}
            >
              <Hint
                content={value}
                size={value}
                style={{ display: "inline-block", width: "96%" }}
              />
            </StyleValue>
            <StyleLableUnit>{item.unit}</StyleLableUnit>
          </div>
        </>
      ) : (
        <>
          <StyleLableUnit style={labelStyle}>{item.label}</StyleLableUnit>
          <StyleLableUnit style={valueStyle}>{item.unit}</StyleLableUnit>
          <StyleValue>{value}</StyleValue>
        </>
      )}
    </div>
  );
};

export default Value;
