/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */
import * as R from "ramda";
import type { SelectProps } from "antd";

import { getDateText } from "utils/stringUtil";

interface BooleanOptions {
  label: string;
  value: boolean;
}

export const platformOptions: SelectProps["options"] = [
  {
    label: "Windows",
    value: "WINDOWS",
  },
  {
    label: "Cloud",
    value: "CLOUD",
  },
];

export const eventsStatus: SelectProps["options"] = [
  {
    label: "Visible",
    value: "visible",
  },
  {
    label: "Invisible",
    value: "invisible",
  },
];

export const visibleOptions: BooleanOptions[] = [
  {
    label: "Visible",
    value: true,
  },
  {
    label: "Invisible",
    value: false,
  },
];

export const AlowReceivingOptions: BooleanOptions[] = [
  {
    label: "Allow",
    value: false,
  },
  {
    label: "Not Allow",
    value: true,
  },
];

export const PublicOptions: BooleanOptions[] = [
  { label: "Public", value: true },
  { label: "Non-Public", value: false },
];

export const builtInOptions: SelectProps["options"] = [
  { label: "Built-in", value: "true" },
  { label: "Customize", value: "false" },
];

export const builtInCheckboxOptions = [
  { text: "Built-in", value: "true" },
  { text: "Customize", value: "false" },
];

export const attributeType: SelectProps["options"] = [
  { label: "String", value: "STRING" },
  { label: "Number", value: "NUMBER" },
  { label: "DateTime", value: "DATETIME" },
  { label: "Boolean", value: "BOOL" },
  { label: "Enumeration", value: "ENUM" },
];

export const environmentOptions = (t: any) => {
  return [
    { value: false, label: t("NonProductionEnvironment") },
    { value: true, label: t("ProductionEnvironment") },
  ];
};

export const displayOptions: { value: any; label: string }[] = [
  { value: "SUM", label: "Total" },
  { value: "AVERAGE", label: "Average" },
];

export const timeScopeOptions: { value: any; label: string; diff?: number }[] =
  [
    {
      label: "By Hour",
      value: "HOUR",
      diff: 30,
    },
    {
      label: "By Day",
      value: "DAY",
    },
    {
      label: "By Week",
      value: "WEEK",
    },
    {
      label: "By Month",
      value: "MONTH",
    },
  ];

export const retentionTimeScopeOptions: {
  value: any;
  label: string;
  diff?: number;
}[] = [
  {
    label: "By Day",
    value: "DAY",
  },
  {
    label: "By Week",
    value: "WEEK",
  },
  {
    label: "By Month",
    value: "MONTH",
  },
];

export const MoTMoRatioOptions: {
  value: any;
  label: string;
  timeScopeDefalut?: string;
  isVisiable?: string[];
}[] = [
  {
    label: "Last Day",
    value: "YOY_DAY",
    isVisiable: ["HOUR"],
  },
  {
    label: "Last Week",
    value: "YOY_WEEK",
    isVisiable: ["HOUR", "DAY"],
  },
  {
    label: "Last Month",
    value: "YOY_MONTH",
    isVisiable: ["HOUR", "DAY"],
  },
  {
    label: "Last Year",
    value: "YOY_YEAR",
    isVisiable: ["HOUR", "DAY", "MONTH"],
  },
];

export const size: any = (setHeight: any = (type: string) => {}) => ({
  small: { w: 1, h: setHeight("small") || 4 },
  medium: { w: 2, h: setHeight("medium") || 8 },
  large: { w: 4, h: setHeight("large") || 8 },
});

export const dashboardType: any = {
  DEFAULT: "Default",
  SHARED: "Shared",
  PRIVATE: "Private",
};

export const periodWindowOptions: {
  value: any;
  label: string;
}[] = [
  //   {
  //     label: "Today",
  //     value: "today",
  //   },
  {
    label: "Customize",
    value: "customize",
  },
];

export const periodWindowOptions_: {
  value: any;
  label: string;
}[] = [
  {
    label: "Today",
    value: 24 * 60 * 60,
  },
  {
    label: "7 Day",
    value: 24 * 60 * 60 * 7,
  },
  {
    label: "14 Day",
    value: 24 * 60 * 60 * 14,
  },
  {
    label: "30 Day",
    value: 24 * 60 * 60 * 30,
  },
];

export const calcMethodOptions: {
  value: any;
  label: string;
}[] = [
  {
    label: "Number of users",
    value: "USER",
  },
  {
    label: "Total times",
    value: "COUNT",
  },
];

export const customizedIndicatorOptions: {
  value: any;
  label: string;
}[] = [
  //   {
  //     label: "Round",
  //     value: "ROUND",
  //   },
  {
    label: "Percentage",
    value: "PERCENTAGE",
  },
  {
    label: "Two Decimal",
    value: "TWO_DECIMAL",
  },
  //   {
  //     label: "Three Decimal",
  //     value: "THREE_DECIMAL",
  //   },
];

export const dateOptionsByTrend = (everyday: any) => {
  return [
    {
      label: "Overall Trend",
      value: "overall",
    },
    ...R.map(
      (i) => ({
        label: i.label,
        value: i.value,
      }),
      everyday,
    ),
  ];
};

export const dateOptionsByComparison = (
  everyday: any,
  retentionFrom: any,
  unit: string,
) => {
  return [
    ...R.addIndex(R.map)(
      (i, idx) => ({
        label: getDateText(idx, retentionFrom, unit),
        value: idx,
      }),
      everyday,
    ),
  ];
};

export const retentionDaysOptions: {
  value: any;
  label: string;
}[] = [
  {
    label: "3Days",
    value: 3,
  },
  {
    label: "7Days",
    value: 7,
  },
  {
    label: "14Days",
    value: 14,
  },
  {
    label: "30Days",
    value: 30,
  },
];

export const retentionWeekOptions: {
  value: any;
  label: string;
}[] = [
  {
    label: "2Week",
    value: 2,
  },
  {
    label: "3Week",
    value: 3,
  },
  {
    label: "4Week",
    value: 4,
  },
  {
    label: "8Week",
    value: 8,
  },
  {
    label: "12Week",
    value: 12,
  },
];

export const retentionMonthOptions: {
  value: any;
  label: string;
}[] = [
  {
    label: "2Month",
    value: 2,
  },
  {
    label: "3Month",
    value: 3,
  },
  {
    label: "4Month",
    value: 4,
  },
  {
    label: "6Month",
    value: 6,
  },
];

export const retentionCalculateByOptions: {
  value: any;
  label: string;
}[] = [
  {
    label: "Retention",
    value: "RETENTION",
  },
  {
    label: "Churn",
    value: "CHURN",
  },
];
