/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";
import { useSpring, animated } from "react-spring";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  onClick?: () => void;
  style: {};
  isActive: boolean;
}

const Events = forwardRef(
  (
    {
      width = 14,
      height = 15,
      className,
      onClick,
      fill,
      style,
      isActive,
      ...restProps
    }: IconProps,
    ref
  ) => {
    const rectStyles = useSpring({
      to: { fill: isActive ? "#229BFF" : "#949494" },
      config: { duration: 500 },
    });
    const rect2Styles = useSpring({
      to: { fill: isActive ? "#5AD3FF" : "#C9CDD4" },
      config: { duration: 500 },
    });

    return (
      <svg
        width={width}
        height={height}
        className={className}
        style={style}
        onClick={onClick}
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <animated.rect
          y="0.5"
          width="10"
          height="11.6"
          rx="1"
          fill="#949494"
          style={rectStyles}
        />
        <g style={{ mixBlendMode: "multiply" }}>
          <animated.rect
            x="4.31982"
            y="4.21429"
            width="7.68"
            height="9.28571"
            rx="1"
            fill="#C9CDD4"
            style={rect2Styles}
          />
        </g>
        <rect
          x="1.43994"
          y="1.42859"
          width="1.92"
          height="1.85714"
          rx="0.5"
          fill="white"
        />
      </svg>
    );
  }
);

export default Events;
