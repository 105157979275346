/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

export const PROJECT_LIST = "projects";
export const PROJECT = "project";

export const PROJECT_USAGE = "projectUsage";

export const ENVIRONMENT_LIST = "environments";

export const EVENT_LIST = "events";

export const INVITATION_CONFIRM_KEY = "INVITATION_CONFIRM_KEY";
export const ATTRIBUTE_QUERY_KEY = "ATTRIBUTE_QUERY_KEY";

export const PROJECT_MEMBERS = "GetProjectMembersKey";
export const PENDING_MEMBERS = "GetPendingMembersKey";

export const DASHBOARD_LIST = "dashboards";
export const DASHBOARD = "dashboard";

export const FUNNEL_ANALYSIS = "funnel_analysis";
export const ATTRIBUTE_USER_ALL = "attributes_user_all";
export const ATTRIBUTE_EVENT_ALL = "attributes_event_all";
export const EVENT_ATTRIBUTE = "event_attributes";
