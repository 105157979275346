/** @format */

import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  FC,
} from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { range, map, filter, path } from "ramda";
import { sharedOptions, sharedUtils } from "horizon-shared-lib";

import { PROJECT, DASHBOARD } from "constants/queryKeys";
import { ResizeCssLoading } from "components/shared/common";
import {
  queryCacheOptions,
  onError,
  useUpdateData,
} from "utils/useQueryConfig";
import { userId } from "utils/constants";
import { auid, typesExist } from "utils/stringUtil";
import * as api from "api";
import ItemContent from "components/Dashboard/ItemContent";
import SingleWidgetHeader from "components/Dashboard/SingleWidgetHeader";
import actions from "components/shared/actions";

const StyledContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
`;

const StyledContent = styled.div`
  width: calc(100vw - 100px);
  height: calc(100vh - 93px);
  padding: 0 50px;
`;

const StyledLoading = styled.div`
  width: calc(100% - 2px);
  height: calc(100% - 85px);
  position: absolute;
  left: 1px;
  top: 62px;
  z-index: 10;
  background: #fffffff7;
`;

const NoData = styled.div`
  color: #949494;
  width: 100%;
  height: calc(100% - 45px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const loadStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
};

interface MainProps {
  widgetId: string;
  projectId: string;
  dashboardId: string;
  widgetType: string;
}

const { ChartTypes } = sharedOptions;
const {
  showUpdateAt,
  dateAt,
  outOfDate,
  getTZ,
  useIntervalAsync,
  errorMsgByCode,
} = sharedUtils;

const Widget: FC<MainProps> = ({
  widgetId,
  projectId,
  dashboardId,
  widgetType,
}) => {
  //   actions.setGlobalState({ showTopBar: false });

  const [chartName, setChartName] = useState<string>("");
  const [dataUpdateAt, setDataUpdateAt] = useState<string>("");
  const [timeScope, setTimeScope] = useState("TOTAL");
  const [stale, toggleStale] = useState(true);
  const [data, setData] = useState(null);
  const [ratioData, setRatioData] = useState(null);
  const [errors, setErrors] = useState<string | null>();
  const [chartFilter, setChartFilter] = useState({});

  const abortControllerRef = useRef<any>(null);
  const abortControllerComparisonRef = useRef<any>(null);

  const { data: projectData } = useQuery(PROJECT, () =>
    api.fetchProject(projectId, {})
  );

  const {
    isSuccess: dashboardIsSuccess,
    isError: dashboardIsError,
    data: dashboardData,
  } = useQuery(
    [DASHBOARD, dashboardId],
    () => api.fetchDashboard(projectId, dashboardId),
    {
      ...queryCacheOptions,
      enabled: !!projectId && !!dashboardId,
    }
  );

  const {
    isSuccess: widgetIsSuccess,
    isError: widgetIsError,
    data: widgetData,
  } = useQuery(
    ["widget", widgetId],
    () => {
      if (widgetType === "EVENT") {
        return api.fetchEventWidget(projectId, widgetId);
      }
      if (widgetType === "FUNNEL") {
        return api.fetchFunnelWidget(projectId, widgetId);
      }
      if (widgetType === "RETENTION") {
        return api.fetchRetentionWidget(projectId, widgetId);
      }
      return {};
    },
    {
      ...queryCacheOptions,
    }
  );

  const {
    mutate: ratioMutate,
    isLoading: ratioLoading,
    error: ratioonError,
  } = useUpdateData(
    null,
    (value) => {
      const abortController = new AbortController();
      abortControllerComparisonRef.current = abortController;
      const { signal } = abortController;
      return api.fetchRelativeComparison(projectId, value, signal);
    },
    null,
    true
  );

  const {
    mutate: Mutate,
    isLoading: loading,
    error,
  } = useUpdateData(
    null,
    (value) => {
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      const { signal } = abortController;
      if (widgetType === "FUNNEL") {
        return api.queryFunnelAnalysis(projectId, value, signal);
      }
      if (widgetType === "RETENTION") {
        return api.queryRetentionAnalysis(projectId, value, signal);
      }
      return api.queryEventAnalysis(projectId, value, signal);
    },
    null,
    true
  );

  const analysis: any = path(["analysis"], widgetData);
  const dynamicTime = path(["dynamicTime"], widgetData);
  const { endAt, startAt } = dateAt(
    analysis,
    dynamicTime,
    path(["meta", "createdAt"], projectData)
  );

  useEffect(() => {
    const err = errorMsgByCode(error);
    setErrors(err);
  }, [error]);

  useEffect(() => {
    setChartName(path(["chartType"], widgetData) || "");
  }, [widgetData]);

  const ratioCallback = useMemo(
    () => ({
      onSuccess(datasource: any) {
        setRatioData(datasource);
      },
      onError(e: any) {
        if (e.code !== 602 && e.code !== 404) {
          onError(e);
        }
      },
    }),
    []
  );

  const updateDashboardCallback = useMemo(
    () => ({
      onSuccess(datasource: any) {
        setData(datasource);
        setDataUpdateAt(showUpdateAt(datasource));
      },
      onError(e: any) {
        if (e.code !== 602 && e.code !== 404) {
          onError(e);
        }
      },
    }),
    []
  );

  const fetchRatio = useCallback(
    (q: any, ans: any) => {
      let numberTypes = ans
        ? path(["numberTypes"], ans)
        : path(["numberTypes"], widgetData);
      numberTypes = numberTypes || [];
      abortControllerComparisonRef.current?.abort();
      ratioMutate(
        {
          ...q,
          compare: {
            yoyRule: path(["compare", "yoyRule"], widgetData) || "YOY_DAY",
            showMomRatio: typesExist("MOM", numberTypes),
            showYoyRatio: typesExist("YOY", numberTypes),
          },
        },
        ratioCallback
      );
    },
    [ratioCallback, ratioMutate, widgetData]
  );

  const fetchQuery = useCallback(
    (result?: any, IsInWidget?: false) => {
      abortControllerRef.current?.abort();
      const ans = path(["analysis"], result);
      let query = ans || analysis;

      if (!IsInWidget) {
        query = { ...query, endAt: endAt, startAt: startAt };
      }

      const err: any = outOfDate(query);

      if (err) {
        setErrors(err);
        return "";
      } else {
        setErrors("");
      }

      const mutateOption = {
        analysis: { ...query, timezone: getTZ() },
        environmentId: path(["environmentId"], dashboardData),
      };

      Mutate(mutateOption, updateDashboardCallback);

      if (
        widgetType === "EVENT" &&
        (typesExist("MOM", path(["numberTypes"], widgetData)) ||
          typesExist("YOY", path(["numberTypes"], widgetData)))
      ) {
        fetchRatio(mutateOption, result);
      }
    },
    [
      Mutate,
      dashboardData,
      analysis,
      widgetType,
      startAt,
      endAt,
      updateDashboardCallback,
      fetchRatio,
      widgetData,
    ]
  );

  const onRefresh = (isNeedLoading: boolean = false) => {
    if (isNeedLoading) {
      setData(null);
    }
    fetchQuery();
  };

  useEffect(() => {
    if (stale && widgetData && dashboardIsSuccess) {
      fetchQuery();
      toggleStale(false);
    }
  }, [stale, widgetData, dashboardIsSuccess, fetchQuery]);

  const memoItem = useMemo(() => {
    return { widget: widgetData };
  }, [widgetData]);

  const memoTimeScope = useMemo(() => {
    return timeScope;
  }, [timeScope]);

  const ChartNode = ChartTypes[chartName];

  return (
    <StyledContainer>
      <SingleWidgetHeader
        dataUpdateAt={dataUpdateAt}
        setTimeScope={setTimeScope}
        timeScope={timeScope}
        onRefresh={onRefresh}
        item={memoItem}
      />
      <StyledContent>
        {!data && loading && (
          <StyledLoading>
            <ResizeCssLoading size="middle" style={loadStyle} />
          </StyledLoading>
        )}
        {dashboardIsSuccess && ChartNode && data && !errors ? (
          <ItemContent
            item={memoItem}
            data={data}
            ratioData={ratioData}
            chartName={chartName}
            timeScope={memoTimeScope}
            nodeRef={document.body}
            fetchQuery={fetchQuery}
            setData={setData}
            chartFilter={chartFilter}
            setChartFilter={setChartFilter}
          />
        ) : (
          <NoData>{!loading && errors}</NoData>
        )}
      </StyledContent>
    </StyledContainer>
  );
};

export default Widget;
