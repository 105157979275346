/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
  onClick?: () => void;
  className?: any;
}

const Table = forwardRef(
  (
    {
      width = 18,
      height = 18,
      fill,
      style,
      onClick,
      className,
      ...restProps
    }: IconProps,
    ref
  ) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...restProps}
    >
      <path d="M7.12549 7.515H10.8755V15.75H7.12549V7.515ZM12.3755 15.75H14.6255C15.4505 15.75 16.1255 15.075 16.1255 14.25V7.5H12.3755V15.75ZM14.6255 2.25H3.37549C2.55049 2.25 1.87549 2.925 1.87549 3.75V6H16.1255V3.75C16.1255 2.925 15.4505 2.25 14.6255 2.25ZM1.87549 14.25C1.87549 15.075 2.55049 15.75 3.37549 15.75H5.62549V7.5H1.87549V14.25Z" />
    </svg>
  )
);

export default Table;
