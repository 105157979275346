/** @format */

export const SIDEBAR_MODE = "ui/SIDEBAR_MODE";
export const namespace = "ui";
export interface uiBaseType {
  sidebarMode: string;
}

export interface uiType extends uiBaseType {
  sidebarMode: string;
}

export type uiActions = {
  type: typeof SIDEBAR_MODE;
  payload: uiType;
};

export const initialState = {
  sidebarMode: "expand", //collapsed
};

export const configs = [
  {
    type: SIDEBAR_MODE,
    state: (state: any, action: any) => ({
      ...state,
      ui: {
        sidebarMode: action.payload.sidebarMode,
      },
    }),
  },
];

const ui = {
  namespace,
  initialState,
  configs,
};

export default ui;
