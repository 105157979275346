/** @format */

import React, { useState, useEffect, useMemo, useRef, FC } from "react";
import styled from "styled-components";
import { range, map, any, path, sum, uniq, join, split } from "ramda";
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  DataZoomComponent,
  MarkLineComponent,
  MarkLineComponentOption,
} from "echarts/components";
import { BarChart, BarSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

import AdaptiveWidget from "components/shared/elements/AdaptiveWidget";
import { echartsColors } from "constants/colors";

import {
  calcHeightByNumTypes,
  dateFormat,
  tooltip,
  yAxisLabelFormat,
} from "./Helper";
import DisplayTotal from "./DisplayTotal";
import { dataAxisWithGroup, lineData } from "./DataHelper";

const StyledContainer = styled(AdaptiveWidget)`
  background: #fff;
  width: 100%;
  height: ${(props: any) => `calc(100% - ${props.headerHeight || 0}px)`};
  border-radius: 4px;
`;

const seriesBase = {
  type: "bar",
  barMaxWidth: 20,
  emphasis: {
    focus: "series",
  },
};

interface BarProps {
  item?: any;
  data?: any;
  barSeries?: [];
  showDataZoom?: boolean;
  headerHeight?: number;
}

const Bar: FC<BarProps> = ({
  item,
  data,
  barSeries,
  showDataZoom = false,
  headerHeight,
}) => {
  const barRef: any = useRef(null);

  const widgetInfo = useMemo(() => {
    return path(["widget"], item);
  }, [item]);

  const formulas = useMemo(() => {
    return path(["analysis", "formulas"], widgetInfo) || [];
  }, [widgetInfo]);

  const dimensions = useMemo(() => {
    return path(["dimensionByNames"], data) || [];
  }, [data]);

  const [series, xAxisLabels, groupBys] =
    dimensions.length > 0
      ? dataAxisWithGroup(data, formulas)
      : lineData(data, formulas);

  const [itemSize, setItemSize] = useState<{ width: number; height: number }>({
    width: 1,
    height: 1,
  });
  const [initChart, setInitChart] = useState<any>();
  const [height, setHeight] = useState<string>();
  const [total, setTotal] = useState<number>(0);
  const [gap, setGap] = useState<any>("30%");

  type EChartsOption = echarts.ComposeOption<
    | TooltipComponentOption
    | GridComponentOption
    | LegendComponentOption
    | MarkLineComponentOption
    | BarSeriesOption
  >;

  echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    BarChart,
    DataZoomComponent,
    CanvasRenderer,
  ]);

  useEffect(() => {
    const myChart = echarts.init(barRef.current);
    setInitChart(myChart);
  }, []);

  useEffect(() => {
    if (initChart) {
      initChart.resize();
    }
  }, [initChart, itemSize, height]);

  useEffect(() => {
    if (initChart && initChart.getOption()) {
      const allSeries = initChart.getOption().series;

      const xAxis = initChart.getModel().getComponent("xAxis");
      const seriesWidth = xAxis.axis.getBandWidth();
      const seriesNum = uniq(map((i) => i.stack, allSeries)).length;

      let barWidth = seriesWidth / (seriesNum + 2);
      barWidth = barWidth > 20 ? 20 : barWidth;

      let barGap: number = 30;

      if (barWidth === 20) {
        const remainingWidth = seriesWidth - seriesNum * barWidth;
        const gaps = remainingWidth / 2 / seriesNum;
        barGap = (gaps / barWidth) * 100;
      }
      setGap(`${barGap}%`);
    }
  }, [initChart, series]);

  useEffect(() => {
    setHeight(calcHeightByNumTypes(widgetInfo));
  }, [widgetInfo]);

  useEffect(() => {
    const myChart = echarts.init(barRef.current);
    setInitChart(myChart);
  }, []);

  useEffect(() => {
    const total = sum(map((i) => sum(path(["data"], i)), series));
    setTotal(total);
  }, [series]);

  useEffect(() => {
    const unit = path(["analysis", "unit"], widgetInfo);

    const barSeries = map(
      (i) => ({
        stack: i.eventName,
        ...i,
        ...seriesBase,
        barGap: gap,
      }),
      series,
    );

    const optionsProps: any = {};

    if (showDataZoom) {
      optionsProps.dataZoom = [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
      ];
    }

    const fontLength = join(",", xAxisLabels || []).length;
    const fontWidth = fontLength * 8;

    const addAxisLabel: any = {};
    if (fontLength * 21.78 < itemSize.width) {
      addAxisLabel.interval = 0;
    }

    const option: EChartsOption = {
      tooltip: {
        ...tooltip(groupBys),
      },
      color: echartsColors,
      grid: {
        left: "3%",
        right: "4%",
        bottom: showDataZoom ? "25%" : "15%",
        top: "8%",
        containLabel: true,
      },
      legend: {
        bottom: showDataZoom ? 45 : 5,
        itemWidth: 14,
        type: "scroll",
      },
      xAxis: {
        type: "category",
        nameRotate: 51,
        data: xAxisLabels,
        axisLine: {
          lineStyle: { color: "#E5E6EB" },
        },
        axisLabel: {
          color: "#86909C",
          ...addAxisLabel,
          rotate: fontWidth > itemSize.width ? -45 : 0,
          formatter: function (value: any) {
            return dateFormat(unit, value);
          },
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: yAxisLabelFormat,
        },
      },
      animation: false,
      series: barSeries,
      ...optionsProps,
    };

    if (initChart) {
      initChart.setOption(option, true);
    }
  }, [
    initChart,
    series,
    groupBys,
    xAxisLabels,
    showDataZoom,
    widgetInfo,
    itemSize,
    gap,
  ]);

  return (
    <StyledContainer setItemSize={setItemSize} headerHeight={headerHeight}>
      <DisplayTotal
        item={widgetInfo}
        data={data}
        total={total}
        average={(total / xAxisLabels.length).toFixed(2)}
      />
      <div
        ref={barRef}
        className="nonDraggable"
        style={{
          height: height,
        }}
      />
    </StyledContainer>
  );
};

export default Bar;
