/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface MenuIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const ArrowIcon = forwardRef(
  ({ width = 14, height = 14, fill, ...restProps }: MenuIconProps, ref) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0977 8.64749C10.961 8.78417 10.7394 8.78417 10.6027 8.64749L7.00018 5.04496L3.39766 8.64748C3.26097 8.78417 3.03937 8.78417 2.90268 8.64748C2.766 8.5108 2.766 8.28919 2.90268 8.15251L6.7527 4.3025C6.81833 4.23686 6.90736 4.19999 7.00018 4.19999C7.09301 4.19999 7.18203 4.23686 7.24767 4.3025L11.0977 8.15251C11.2344 8.2892 11.2344 8.5108 11.0977 8.64749Z"
        fill="#212121"
      />
    </svg>
  )
);

export default ArrowIcon;
