/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
  onClick?: () => void;
  className?: any;
}

const Small = forwardRef(
  (
    {
      width = 72,
      height = 32,
      fill,
      style,
      onClick,
      className,
      ...restProps
    }: IconProps,
    ref
  ) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 72 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...restProps}
    >
      <rect y="0.000976562" width="34" height="14" rx="2" />
      <rect
        x="38.5"
        y="0.500977"
        width="33"
        height="13"
        rx="1.5"
        fill="white"
        strokeDasharray="2 2"
      />
      <rect
        x="0.5"
        y="18.501"
        width="33"
        height="13"
        rx="1.5"
        fill="white"
        strokeDasharray="2 2"
      />
      <rect
        x="38.5"
        y="18.501"
        width="33"
        height="13"
        rx="1.5"
        fill="white"
        strokeDasharray="2 2"
      />
    </svg>
  )
);

export default Small;
