/** @format */

import * as R from "ramda";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { LooseStaticObject } from "constants/interface";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const formatNormalMonth = "YYYYMM";
export const formatMonth = "YYYY-MM";
export const formatNormal = "YYYYMMDD";
export const format = "YYYY-MM-DD";
export const formathms = "YYYY-MM-DDTHH:mm:ss";
export const format0hms = "YYYY-MM-DD HH:mm:ss";
export const formatDot = "YYYY.MM.DD";
export const formatMonthDay = "MM-DD";
export const formatMDhm = "MM-DD HH:mm";

export const getTZ = () => dayjs.tz.guess();

const GetDateLocal = (date: any, f: string) =>
  dayjs(date).utcOffset(getTZ()).format(f);

export const GetDateNow = (f: string) => dayjs().utcOffset(getTZ()).format(f);

export const GetDateString = (timestamp: number, f?: string | undefined) => {
  if (!timestamp) {
    return dayjs().format(f || format);
  }
  let date = new Date(timestamp * 1000);

  return GetDateLocal(date, f || format);
};

export const GetLocalDate = (timestamp: number) => {
  if (!timestamp) {
    return dayjs();
  }
  let date = new Date(timestamp);
  return dayjs(date).utcOffset(getTZ());
};

export const GetDateMonthDayString = (timestamp: number | string) => {
  if (!timestamp) {
    return GetDateNow(formatMonthDay);
  }
  let date = new Date(timestamp);
  return GetDateLocal(date, formatMonthDay);
};

export const formatDate = (date: any, type?: string | undefined) => {
  return dayjs(date).format(type === "dot" ? formatDot : format);
};

export const formatLocalDate = (date: any, type?: string | undefined) => {
  return dayjs(date)
    .local()
    .format(type === "dot" ? formatDot : format);
};

export const getTodayUTC = () => {
  const now = dayjs.utc();
  return now.format(format);
};

export const diffDates = (start: any, end: any) =>
  dayjs(end).diff(dayjs(start), "day");

export const getTimeNow = () => new Date().getTime();

export const getEveryDay = (start: number, end: number, type?: string) => {
  const startDate = dayjs(start * 1000);
  const endDate = dayjs(end * 1000);
  let currentDate = startDate;
  const dates = [];
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
    if (type === "object") {
      dates.push({
        label: currentDate.format(formatNormal),
        value: currentDate.format("YYYY-MM-DD"),
      });
    } else {
      dates.push(currentDate.format(formatNormal));
    }
    currentDate = currentDate.add(1, "day");
  }
  return dates;
};
