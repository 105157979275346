import {
  map,
  sum,
  path,
  any,
  join,
  addIndex,
  flatten,
  equals,
  uniq,
  filter,
  toLower,
} from "ramda";

import { processRows } from "services/analysis";
import {
  addIndexToDuplicateNames,
  calculateFormula,
  generateNumberToLetterMap,
  numberWithUnit,
} from "utils/stringUtil";

const formulaValue: any = (formula: any, row: any) =>
  map((num: any) => {
    const variables: any = {};
    addIndex(map)((value, idx) => {
      variables[generateNumberToLetterMap()[idx]] = value;
    }, num);
    let total = calculateFormula(formula.value, variables);
    if (formula.format === "PERCENTAGE") {
      total = total * 100;
    }
    if (formula.format === "TWO_DECIMAL") {
      total = total.toFixed(2);
    }
    return total;
  }, row.values);

export const formatData = (
  values: any,
  xAxis: any,
  unit: any,
  groupBy: any,
  eventsName: any,
  index: any,
  byValues?: any,
) =>
  map(
    (num: any) => ({
      value:
        typeof num === "number" || typeof num === "string"
          ? num
          : path([index], num),
      eventName: eventsName,
      date: path([index], xAxis) || "",
      units: unit || "",
      name: byValues,
      groupBy: groupBy,
    }),
    values,
  );

export const lineData = (analysis: any, formulas?: any) => {
  const xAxisLabels = path(["dates"], analysis) || [];
  const rows = path(["details"], analysis) || [];
  const groupBys = path(["groupByNames"], analysis) || [];
  const units = path(["units"], analysis) || [];
  const groupBy = join(",", groupBys);

  const events = path(["indicatorNames"], analysis) || [];
  const seriesBase = [];
  const seriesWithCustom = [];

  const dupEventsNames = addIndexToDuplicateNames(events);

  for (let i = 0; i < (formulas || []).length; i += 1) {
    const formula: any = path([i], formulas);
    for (let j = 0; j < rows.length; j += 1) {
      const values: [] = path(["values"], path([j], rows)) || [];
      const byValues: string | undefined = join(
        ",",
        path([j, "byValues"], rows),
      );
      const joinByValues = join(",", rows[j].byValues);
      const formulaValue = map((num: any) => {
        const variables: any = {};
        addIndex(map)((value, idx) => {
          variables[generateNumberToLetterMap()[idx]] = value;
        }, num);
        let total = calculateFormula(formula.value, variables);
        if (formula.format === "PERCENTAGE") {
          total = total * 100;
        }

        if (formula.format === "TWO_DECIMAL") {
          total = total.toFixed(2);
        }

        return total;
      }, values);
      seriesWithCustom.push({
        id: `${formula.format}_customized_indicator_${i + 1}${j}`,
        eventName: "",
        name: `${joinByValues} customized indicator ${i + 1}`,
        data: formatData(
          formulaValue,
          xAxisLabels,
          formula.format === "PERCENTAGE" ? "%" : "",
          groupBy,
          `Customized indicator_${i + 1}`,
          j,
          byValues,
        ),
      });
    }
  }

  for (let i = 0; i < rows.length; i += 1) {
    const values: [] = path(["values"], path([i], rows)) || [];
    const valuesLength: any = dupEventsNames.length;
    const byValues: string | undefined = join(",", path([i, "byValues"], rows));
    for (let j = 0; j < valuesLength; j += 1) {
      seriesBase.push({
        id: `${path([j], dupEventsNames)}_${i}_${j}`,
        eventName: path([j], dupEventsNames),
        name: `${path([j], dupEventsNames)}${byValues ? `, ${byValues}` : ""}`,
        byValues: byValues,
        data: formatData(
          values,
          xAxisLabels,
          path([j], units),
          groupBy,
          path([j], dupEventsNames),
          j,
          byValues,
        ),
      });
    }
  }

  const series = (formulas || []).length > 0 ? seriesWithCustom : seriesBase;

  return [series, xAxisLabels, groupBys];
};

const setFormula: any = (filterByValues: any, formula: any[]) => {
  const sumGroup = sum(map((b: any) => sum(flatten(b.values)), filterByValues));
  return formulaValue(formula, { values: [[sumGroup]] });
};

export const dataAxisWithGroup = (analysis: any, formulas?: any) => {
  let xAxisLabels: any = path(["dates"], analysis) || [];
  const rows = path(["details"], analysis) || [];
  const groupBys = path(["groupByNames"], analysis) || [];
  const groupByDisplayNames = path(["groupByDisplayNames"], analysis) || [];
  const dimensions = path(["dimensionByNames"], analysis) || [];

  const units = path(["units"], analysis) || [];
  const groupBy = join(",", groupBys);
  const groupByIndexs: any = [];

  addIndex(map)((gb: any, idx: number) => {
    if (any((i: any) => toLower(i) !== toLower(gb))(dimensions)) {
      groupByIndexs.push(idx);
    }
  }, groupBys);

  const events = path(["indicatorNames"], analysis) || [];
  const seriesWithCustom = [];
  const dupEventsNames = addIndexToDuplicateNames(events);

  const xMapRowsByGroupBy: any = addIndex(map)((i: any) => {
    if (groupByIndexs.length > 0) {
      return addIndex(filter)(
        (item: any, idx: number) => any((g) => idx !== g)(groupByIndexs),
        i.byValues,
      );
    }
    return i.byValues;
  }, rows);

  const yMapRowsByGroupBy: any = addIndex(map)((i: any) => {
    return groupByIndexs.length > 0
      ? addIndex(filter)(
          (item, idx) => any((g) => idx === g)(groupByIndexs),
          i.byValues,
        )
      : [];
  }, rows);

  const joinByX = uniq(map((i) => join(",", i), xMapRowsByGroupBy));
  const byX = uniq(flatten(xMapRowsByGroupBy));

  const byY = uniq(flatten(yMapRowsByGroupBy));

  for (let i = 0; i < (formulas || []).length; i += 1) {
    const formula: any = path([i], formulas);
    const formulaUnit = formulas[i].format === "PERCENTAGE" ? "%" : "";

    const setValues = (
      num: any,
      idx: number,
      name?: string | undefined | unknown,
    ) => {
      return {
        value: num[0],
        eventName: `Customized indicator ${i + 1}`,
        date: path([idx], joinByX) || "",
        units: formulaUnit || "",
        name: name,
        groupBy: groupBy,
        hide: false,
      };
    };

    if (byY.length === 0 || equals(byY.sort(), byX.sort())) {
      const values: any = addIndex(map)((item, index) => {
        return setFormula([item], formula);
      }, rows);

      // addIndex(map)((item, index) => {
      // const filterByValues = filter(
      //   (r: any) => any((b) => item === b)(r.byValues),
      //   rows
      // );
      // return setFormula(rows, formula);
      // }, byX);

      const mapValues = (num: any, idx: number) =>
        setValues(num, idx, joinByX[idx]);

      seriesWithCustom.push({
        id: `${path(["id"], formula)}_${i}`,
        name: `Customized indicator ${i + 1}`,
        data: addIndex(map)(mapValues, values || []),
      });
    } else {
      for (let j = 0; j < byY.length; j += 1) {
        const values = addIndex(map)((item, index) => {
          const filterByValues = filter(
            (r: any) =>
              any((b) => item === b)(r.byValues) &&
              any((b) => byY[j] === b)(r.byValues),
            rows,
          );
          return setFormula(filterByValues, formula);
        }, byX);

        const mapValues = (num: any, idx: number) =>
          setValues(num, idx, byY[j]);
        seriesWithCustom.push({
          id: `${path(["id"], formula)}_${i}_${j}`,
          name: `Customized indicator ${i + 1}${byY[j] ? `, ${byY[j]}` : ""}`,
          data: addIndex(map)(mapValues, values || []),
          stack: `indicator${i}`,
        });
      }
    }
  }

  const seriesGroupBys = [];

  xAxisLabels = joinByX;

  for (let i = 0; i < events.length; i += 1) {
    const setValues = (
      num: any,
      idx: number,
      name?: string | undefined | unknown,
      hide?: boolean | undefined | unknown,
    ) => {
      return {
        value: num,
        eventName: path([i], dupEventsNames),
        date: path([idx], joinByX) || "",
        units: path([i], units) || "",
        name: name,
        groupBy: groupBy,
        hide: hide,
      };
    };

    if (byY.length === 0 || equals(byY.sort(), byX.sort())) {
      const values: any = map((b) => {
        const mapValues: any = map((v) => v[i], b.values);
        return sum(mapValues);
      }, rows);

      // addIndex(map)((item: any, index: number) => {
      // const filterByValues = filter(
      //   (r: any) => any((b) => item === b)(r.byValues),
      //   rows
      // );
      //     const sumGroup: any = map((b) => {
      //       const mapValues: any = map((v) => v[i], b.values);
      //       return sum(mapValues);
      //     }, rows);

      //     return sum(sumGroup);
      //   }, byX);

      const mapValues = (num: any, idx: number) => setValues(num, idx);

      seriesGroupBys.push({
        id: `${path([i], events)}_${i}`,
        eventName: path([i], events),
        name: path([i], events),
        data: addIndex(map)(mapValues, values || []),
      });
    } else {
      for (let j = 0; j < byY.length; j += 1) {
        const values = addIndex(map)((item, index) => {
          const filterByValues = filter(
            (r: any) =>
              any((b) => item === b)(r.byValues) &&
              any((b) => byY[j] === b)(r.byValues),
            rows,
          );
          const sumGroup: any = map((b) => {
            const mapValues: any = map((v) => v[i], b.values);
            return sum(mapValues);
          }, filterByValues);

          return sum(sumGroup);
        }, byX);

        const mapValues = (num: any, idx: number) => {
          const filterDetails = filter(
            (r: any) => any((b) => byX[idx] === b)(r.byValues),
            rows,
          );
          const mapDetail: any = map(
            (filterDetail) =>
              filter((f) => f !== byX[idx], filterDetail.byValues),
            filterDetails,
          );
          const eachFilterDetails = flatten(mapDetail);
          const hide = !any((b) => byY[j] === b)(eachFilterDetails);
          return setValues(num, idx, byY[j], hide);
        };

        seriesGroupBys.push({
          id: `${path([i], events)}_${byY[j]}`,
          eventName: path([i], events),
          name: `${path([i], events)}${byY[j] ? `, ${byY[j]}` : ""}`,
          stack: `${path([i], events)}`,
          data: addIndex(map)(mapValues, values || []),
        });
      }
    }
  }

  let series: any =
    (formulas || []).length > 0 ? seriesWithCustom : seriesGroupBys;
  return [series, xAxisLabels, groupByDisplayNames];
};

export const pieData = (analysis: any, formulas: any) => {
  const rows: any = processRows(path(["details"], analysis) || [], formulas);
  const units = path(["units"], analysis) || [];
  const events: [] =
    formulas.length > 0
      ? addIndex(map)((i, idx) => `Customized Indicator ${idx + 1}`, formulas)
      : path(["indicatorNames"], analysis) || [];
  const eventLength: any = events.length;

  const seriesSource: any = [];
  const allTotal: {}[] = [];

  for (let i = 0; i < eventLength; i += 1) {
    const pieData = [];
    const eventNums: number[] = [];
    for (let j = 0; j < rows.length; j += 1) {
      const findJValue = path([j, "values"], rows);
      const byValues: string | undefined = join(
        ",",
        path([j, "byValues"], rows),
      );
      const sumValueByGroup = sum(map((n) => path([i], n), findJValue));

      eventNums.push(sumValueByGroup);
      pieData.push({
        value: parseFloat(sumValueByGroup.toFixed(5)),
        name: byValues ? `${byValues}` : "",
      });
    }
    allTotal.push({
      total: parseFloat(sum(eventNums).toFixed(5)),
      label: path([i], events),
    });

    seriesSource.push({
      name: events[i],
      type: "pie",
      radius: ["50%", "70%"],
      center: ["50%", "50%"],
      left: `${((i * (100 / eventLength)) / 100) * 100}%`,
      right: `${((eventLength - i - 1) * 100) / eventLength}%`,
      itemStyle: {
        borderRadius: 0,
        borderColor: "#fff",
        borderWidth: 2,
      },
      labelLine: {
        show: true,
      },
      label: {
        show: true,
        formatter: "{b}\n {d}%",
      },
      tooltip: {
        formatter: function (params: any) {
          return `${
            params.seriesName
          }<br /><div style="display:inline-block;border-radius:50%;width:8px;height:8px;background-color:${
            params.color
          };margin-right:10px;"></div>${
            params.name
          } <b style='margin-left:10px;'>${numberWithUnit(
            params.value,
            units[i],
          )}</b>`;
        },
      },
      avoidLabelOverlap: true,
      data: pieData,
      emphasis: {},
    });
  }
  return [seriesSource, allTotal];
};
