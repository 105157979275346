/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";
import { useSpring, animated } from "react-spring";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  onClick?: () => void;
  style: {};
  isActive: boolean;
}

const ProjectSettingIcon = forwardRef(
  (
    {
      width = 15,
      height = 15,
      fill,
      className,
      onClick,
      style,
      isActive,
      ...restProps
    }: IconProps,
    ref
  ) => {
    const pathStyles = useSpring({
      to: { fill: isActive ? "#229BFF" : "#949494" },
      config: { duration: 500 },
    });
    const circleStyles = useSpring({
      to: { fill: isActive ? "#5AD3FF" : "#C9CDD4" },
      config: { duration: 500 },
    });

    return (
      <svg
        width={width}
        height={height}
        className={className}
        style={style}
        onClick={onClick}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <animated.path
          d="M11.5803 7.585C11.605 7.393 11.6235 7.198 11.6235 7C11.6235 6.802 11.605 6.607 11.5803 6.415L12.8846 5.422C13.0018 5.332 13.0357 5.17 12.9586 5.038L11.7253 2.959C11.6482 2.83 11.4878 2.776 11.3491 2.83L9.81357 3.433C9.49599 3.196 9.14756 2.995 8.77139 2.842L8.54014 1.252C8.51239 1.111 8.38597 1 8.2318 1H5.76511C5.61094 1 5.48452 1.111 5.45986 1.252L5.22861 2.842C4.85586 2.99326 4.50541 3.192 4.18643 3.433L2.65091 2.83C2.58266 2.80467 2.50739 2.80373 2.4385 2.82735C2.36961 2.85098 2.31158 2.89763 2.27474 2.959L1.04139 5.038C0.964308 5.167 0.998226 5.329 1.11539 5.422L2.41657 6.415C2.39191 6.607 2.37341 6.802 2.37341 7C2.37341 7.198 2.39191 7.393 2.41657 7.585L1.11539 8.578C0.998226 8.668 0.964308 8.83 1.04139 8.962L2.27474 11.041C2.35182 11.17 2.51216 11.224 2.65091 11.17L4.18643 10.567C4.50401 10.804 4.85243 11.005 5.22861 11.158L5.45986 12.748C5.48452 12.889 5.61094 13 5.76511 13H8.2318C8.38597 13 8.51239 12.889 8.53706 12.748L8.76831 11.158C9.14105 11.0067 9.4915 10.808 9.81049 10.567L11.346 11.17C11.4143 11.1953 11.4895 11.1963 11.5584 11.1726C11.6273 11.149 11.6853 11.1024 11.7222 11.041L12.9555 8.962C13.0326 8.833 12.9987 8.671 12.8815 8.578L11.5803 7.585ZM6.99846 9.1C5.80519 9.1 4.8401 8.161 4.8401 7C4.8401 5.839 5.80519 4.9 6.99846 4.9C8.19172 4.9 9.15681 5.839 9.15681 7C9.15681 8.161 8.19172 9.1 6.99846 9.1Z"
          fill="#949494"
          style={pathStyles}
        />
        <g style={{ mixBlendMode: "multiply" }}>
          <animated.circle
            cx="7"
            cy="7"
            r="1"
            fill="#C9CDD4"
            style={circleStyles}
          />
        </g>
      </svg>
    );
  }
);

export default ProjectSettingIcon;
