/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
  onClick?: () => void;
  className?: any;
}

const WarningIcon = forwardRef(
  (
    {
      width = 18,
      height = 18,
      fill = "#F53F3F",
      style,
      onClick,
      className,
      ...restProps
    }: IconProps,
    ref
  ) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666504 8.99984C0.666504 4.39746 4.39746 0.666504 8.99984 0.666504C13.6022 0.666504 17.3332 4.39746 17.3332 8.99984C17.3332 13.6022 13.6022 17.3332 8.99984 17.3332C4.39746 17.3332 0.666504 13.6022 0.666504 8.99984ZM8.1665 11.4998V13.1665H9.83317V11.4998H8.1665ZM9.83317 10.6665L9.83317 4.83317H8.1665L8.1665 10.6665H9.83317Z"
        fill={fill}
      />
    </svg>
  )
);

export default WarningIcon;
