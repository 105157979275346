/** @format */

import React, { FC, useMemo, ReactNode, CSSProperties } from "react";
import styled from "styled-components";
import { assoc, path } from "ramda";
import { Table, ConfigProvider, TablePaginationConfig } from "antd";

import { ResizeCssLoading } from "components/shared/common";
import Hint from "components/shared/elements/Hint";
import { auid } from "utils/stringUtil";
import EmptyBox from "assets/EmptyBox";
import AutoResizeNode from "components/shared/elements/AutoResizeNode";
import { ColumnsType, ColumnType, TableProps } from "antd/es/table";
import { GetComponentProps, TableComponents } from "rc-table/lib/interface";

export const wrapperColumn: <RecordType>(
  columns: AColumnsType<RecordType>,
) => ColumnsType<RecordType> = (columns) =>
  columns.map((column) => {
    const { render, hideHint, hint, editable, fixContent, fixContentStyle } =
      column;
    if (editable || hideHint) return column;

    const hintRender = (text: any, record: any, index: number) => {
      const content =
        typeof render === "function" ? render(text, record, index) : text;
      const hintContent =
        (typeof hint === "function" ? hint(text, record, index) : hint) ||
        content;
      const HC = <Hint content={hintContent}>{content}</Hint>;
      return (
        <>
          {typeof fixContent === "function" ? (
            <div style={{ display: "grid", ...fixContentStyle }}>
              {HC}
              {fixContent(text, record)}
            </div>
          ) : (
            HC
          )}
        </>
      );
    };
    return { ...column, render: hintRender };
  });

const StyledTable = styled(Table)`
  && {
    .ant-table-pagination.ant-pagination {
      // margin-right: 4px !important;
    }
    .ant-pagination li.ant-pagination-item-active {
      border: 0;
      border-radius: 4px;
      background: #e8f3ff;
    }
    .ant-pagination .ant-pagination-options .ant-select .ant-select-selector {
      border-radius: 4px;
    }
    th.ant-table-cell {
      &::before {
        width: 0 !important;
      }
      color: #2e2e2e !important;
      background: #f2f3f5 !important;
      font-weight: 500 !important;
    }
    .ant-table-thead td.ant-table-cell {
      &::before {
        width: 0 !important;
      }
      color: #2e2e2e !important;
      background: #f2f3f5 !important;
      font-weight: 500 !important;
    }
    .ant-table-cell {
      height: 40px !important;
      padding: 6px 16px !important;
      border-radius: 0 !important;
      border-top: 0 !important;
      border-bottom: 1px solid #e5e6eb !important;
      font-style: normal;
      font-size: 14px;
      line-height: 22px;
    }
    .ant-pagination
      .ant-pagination-options
      .ant-pagination-options-quick-jumper
      input {
      border-radius: 4px;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: #f3f9ff !important;
    }
    .ant-table-placeholder .ant-table-cell {
      border-bottom: none !important;
    }

    .ant-table-row .editable-cell-value-wrap {
      // border-radius: 2px;
      height: 23px;
      line-height: 24px;
      padding-right: 10px;
      width: 110px;
      cursor: pointer;
    }
    .ant-table-row:hover .editable-cell-value-wrap .edit-cell-display {
      background: #efefef;
      transition: 0.3s background;
    }
  }
`;

export interface AColumnType<RecordType = any> extends ColumnType<RecordType> {
  hideHint?: boolean;
  hint?: any;
  editable?: boolean;
  fixContent?:
    | React.ReactNode
    | ((text: any, record: RecordType) => React.ReactNode);
  fixContentStyle?: CSSProperties;
}
export type AColumnsType<RecordType = any> = AColumnType<RecordType>[];

export interface ATableProps<RecordType = any> {
  columns: AColumnsType;
  /**
   * If data type is second, you don't need specific pagesize and current.
   */
  data?:
    | RecordType[]
    | {
        elements: RecordType[];
        limit?: number;
        offset?: number;
        count?: number;
      };
  rowSelection?: any;
  size?: any;
  pagination?: false | TablePaginationConfig;
  isPagination?: boolean;
  showSizeChanger?: boolean;
  testName?: string;
  onTableChange?: (pagination: any, filters: any, sorter: any) => void;
  onChange?: (page?: number | undefined, pageSize?: number | undefined) => void;
  onShowSizeChange?: () => void;
  pageSize?: number;
  current?: number;
  onRow?: GetComponentProps<RecordType>;
  height?: number;
  EmptyDescription?: string | ReactNode;
  isEmptyAutosize?: boolean;
  loading?: boolean;
  components?: TableComponents<RecordType>;
  placeHolder?: ReactNode;
  className?: string;
  scroll?: TableProps<RecordType>["scroll"];
}

const ATable: FC<ATableProps> = ({
  data,
  columns,
  rowSelection,
  pageSize = 15,
  current = 1,
  size = "middle",
  loading = false,
  isPagination = true,
  showSizeChanger = true,
  pagination,
  testName,
  className,
  onTableChange,
  onChange,
  onShowSizeChange,
  onRow,
  height,
  isEmptyAutosize = true,
  EmptyDescription = "",
  components,
  placeHolder,
  ...restProps
}) => {
  let elements: any[] = [];
  let count: number = 0;
  let page: number = 1;

  let defaultPagination = useMemo<TablePaginationConfig>(
    () => ({
      defaultCurrent: 1,
      size: "small",
      showSizeChanger: showSizeChanger,
      showQuickJumper: true,
      hideOnSinglePage: false,
      defaultPageSize: 15,
      pageSizeOptions: [15, 30, 50, 100],
      onChange,
      onShowSizeChange,
      pageSize,
    }),
    [onChange, onShowSizeChange, pageSize, showSizeChanger],
  );
  if (data) {
    if (Array.isArray(data)) {
      elements = data;
      count = data.length;
      defaultPagination = assoc("current", current, defaultPagination);
    } else {
      ({ elements } = data);

      const { limit, offset } = data;
      page = Math.ceil((offset || 0) / (limit || 15)) + 1;
      count = data.count === undefined ? 10 : data.count;
      defaultPagination = assoc("current", page, defaultPagination);
      defaultPagination.pageSize = limit === undefined ? 10 : limit;
    }
  }

  defaultPagination = assoc("total", count, defaultPagination);
  defaultPagination = assoc(
    "showTotal",
    () => {
      return `Total:  ${count || 0}`;
    },
    defaultPagination,
  );

  const tableProps: { scroll?: {}; components?: TableComponents<any> } = {};
  if (height && height > 0) {
    tableProps.scroll = { y: height };
  }

  if (components) {
    tableProps.components = { ...components };
  }
  return (
    <ConfigProvider
      renderEmpty={() => {
        if (placeHolder) return placeHolder;
        const emptyBoxNode = (
          <div data-auid={auid("common", "table", `empty_${testName}`)}>
            <EmptyBox />
            <div style={{ color: "#949494", fontSize: "18px" }}>
              {EmptyDescription}
            </div>
          </div>
        );

        return (
          <>
            {isEmptyAutosize ? (
              <AutoResizeNode
                diff={100}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "200px",
                }}
              >
                {emptyBoxNode}
              </AutoResizeNode>
            ) : (
              emptyBoxNode
            )}
          </>
        );
      }}
    >
      <StyledTable
        dataSource={elements}
        columns={wrapperColumn(columns)}
        rowSelection={rowSelection}
        loading={{
          indicator: (
            <ResizeCssLoading
              size="large"
              style={{ height: "100%" }}
              afterOpacity={0.5}
            />
          ),
          spinning: loading,
          delay: 300,
        }}
        rowKey={(record: any) =>
          path(["id"], record) ||
          path(["key"], record) ||
          path(["name"], record)
        }
        pagination={isPagination && (pagination || defaultPagination)}
        data-auid={auid("common", "table", testName)}
        onChange={onTableChange}
        size={size}
        onRow={onRow}
        className={className}
        {...tableProps}
        {...restProps}
      />
    </ConfigProvider>
  );
};

export default ATable;
