import { Select } from "antd";
import { path, map } from "ramda";
import React, { useState, useEffect, useCallback, FC } from "react";
import styled from "styled-components";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "locales";

import {
  getEveryDay,
  formatNormal,
  format,
  formatMonth,
  formatNormalMonth,
} from "utils/dateUtil";
import { dateOptionsByTrend, dateOptionsByComparison } from "constants/options";
import { auid } from "utils/stringUtil";

dayjs.extend(utc);

const StyledDateFilter = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

interface DateSelectProps {
  parentRef?: any;
  analysis?: any;
  dataType: string;
  dateSection?: string;
  setDateSection: any;
  onDates: string[];
  startDates: string[];
  unit: string;
  size?: string;
}

const DateSelect: FC<DateSelectProps> = ({
  parentRef,
  analysis,
  dataType,
  unit,
  size,
  onDates,
  startDates,
  dateSection = "overall",
  setDateSection,
}) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  const getEveryDay = useCallback(
    (dates: any) => {
      return (
        map((i) => {
          const currentDate = dayjs(new Date(i));
          if (unit === "WEEK") {
            return {
              label: i,
              value: i,
            };
          }
          if (unit === "MONTH") {
            return {
              label: currentDate.format(formatNormalMonth),
              value: currentDate.format(formatMonth),
            };
          }
          return {
            label: currentDate.format(formatNormal),
            value: currentDate.format(format),
          };
        }, dates || []) || []
      );
    },
    [unit]
  );

  useEffect(() => {
    //getEveryDay(
    //   path(["startAt"], analysis),
    //   path(["endAt"], analysis),
    //   "object"
    // );

    if (dataType === "trend") {
      const everyDay: any = getEveryDay(startDates);

      const trendOptions = dateOptionsByTrend(everyDay);
      setOptions(trendOptions);
      setDateSection(path([0, "value"], trendOptions));
    }

    if (dataType === "comparison") {
      const everyDay: any = getEveryDay(onDates);
      const ordinalSuffix: any = dateOptionsByComparison(
        everyDay,
        path(["retentionFrom"], analysis),
        unit
      );
      setOptions(ordinalSuffix);
      setDateSection(path([0, "value"], ordinalSuffix));
    }
  }, [
    dataType,
    analysis,
    startDates,
    onDates,
    getEveryDay,
    unit,
    setDateSection,
  ]);

  const onChangeType = (v: string) => {
    setDateSection(v);
  };

  const selectProps: any = {};
  if (size) {
    selectProps.size = size;
  }

  return (
    <StyledDateFilter>
      Date
      <Select
        value={dateSection}
        style={{ width: 140 }}
        onChange={onChangeType}
        getPopupContainer={() => parentRef || document.body}
        options={options}
        {...selectProps}
      />
    </StyledDateFilter>
  );
};

export default DateSelect;
