/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: any;
  onClick?: () => void;
  style: {};
}

const ArrowLeft = forwardRef(
  (
    {
      width = 7,
      height = 12,
      className,
      onClick,
      fill,
      style,
      ...restProps
    }: IconProps,
    ref
  ) => (
    <svg
      width={width}
      height={height}
      className={className}
      style={style}
      onClick={onClick}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35355 0.146447C6.54882 0.341709 6.54882 0.658291 6.35355 0.853553L1.20709 6.00002L6.35355 11.1465C6.54881 11.3417 6.54881 11.6583 6.35355 11.8536C6.15829 12.0489 5.84171 12.0489 5.64644 11.8536L0.146427 6.35357C0.0526588 6.2598 -1.98184e-05 6.13263 -1.98126e-05 6.00002C-1.98068e-05 5.86741 0.0526588 5.74023 0.146427 5.64646L5.64645 0.146447C5.84171 -0.0488156 6.15829 -0.0488155 6.35355 0.146447Z"
        fill="#2E2E2E"
      />
    </svg>
  )
);

export default ArrowLeft;
