/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
  onClick?: () => void;
  className?: any;
}

const ChinaMap = forwardRef(
  (
    {
      width = 18,
      height = 18,
      fill,
      style,
      onClick,
      className,
      ...restProps
    }: IconProps,
    ref
  ) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      {...restProps}
    >
      <path d="M6.74984 2.97003C5.60684 3.34803 4.58984 4.23003 4.58984 4.23003L6.29984 4.95003L8.54984 3.87003L8.90984 2.79003C8.90984 2.79003 7.75784 2.63703 6.74984 2.97003Z" />
      <path d="M8.36992 8.10002L6.29992 6.30002L3.41992 6.21002L3.68992 9.81002L5.21992 10.71L6.38992 14.49L10.3499 10.26L10.4399 8.10002H8.36992Z" />
      <path d="M14.13 5.22004L11.61 3.51004L10.71 4.41004L11.07 5.94004L12.78 6.57004L13.77 7.56004L14.85 8.37004L14.04 9.36004L12.51 13.95L14.94 11.52L15.3 8.28004L14.13 5.22004Z" />
      <path d="M8.95498 3.06004C12.204 3.06004 14.85 5.70604 14.85 8.95504C14.85 12.204 12.204 14.85 8.95498 14.85C5.70598 14.85 3.05998 12.204 3.05998 8.95504C3.05998 5.70604 5.70598 3.06004 8.95498 3.06004ZM8.95498 1.98004C5.10298 1.98004 1.97998 5.10304 1.97998 8.95504C1.97998 12.807 5.10298 15.93 8.95498 15.93C12.807 15.93 15.93 12.807 15.93 8.95504C15.93 5.10304 12.807 1.98004 8.95498 1.98004Z" />
    </svg>
  )
);

export default ChinaMap;
