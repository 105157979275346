/** @format */

import React, { FC, CSSProperties } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.div`
  position: absolute;
  top: 36px;
  width: 88px;
  font-size: 14px;
  text-align: center;
  background: linear-gradient(
    90deg,
    #6666ff -3.17%,
    #5883ff 51.8%,
    #5ad3ff 110.32%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const LoadingStyled = styled.div<{
  opacity: number;
}>`
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  position: relative;
  width: 86px;
  height: 86px;
  border-radius: 50%;
  background: linear-gradient(#5883ff, #5ad3ff, #6666ff);
  animation: animate 1.2s linear infinite;
  span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#5883ff, #5ad3ff, #6666ff);
    left: 0;
  }
  span:nth-child(1) {
    filter: blur(5px);
  }
  span:nth-child(2) {
    filter: blur(10px);
  }
  span:nth-child(3) {
    filter: blur(25px);
  }
  span:nth-child(4) {
    filter: blur(50px);
  }
  &:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background: #f1f1f1;
    border: solid white 1px;
    border-radius: 50%;
    opacity: ${(props: { opacity: number }) => props.opacity || 1};
  }
`;

const LoadingSpan = styled.span`
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animloader {
    50% {
      transform: scale(1) translate(-50%, -50%);
    }
  }

  width: 48px;
  height: 48px;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #fff;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  &:after,
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background: linear-gradient(
      185.36deg,
      #afdbff -59.27%,
      #5ad3ff -8.06%,
      #7c71ff 87.16%
    );
    border-radius: 50%;
    animation: animloader 1s infinite ease-in-out;
  }
  &:before {
    background: linear-gradient(185.36deg, #afdbff -11.58%, #5ad3ff 41.95%);
    transform: scale(0.5) translate(-48px, -48px);
  }
`;

interface LoadingProps {
  text?: string | undefined | boolean;
  style?: CSSProperties;
  afterOpacity?: number;
}

const Loading: FC<LoadingProps> = ({
  text = "Loading",
  style,
  afterOpacity = 1,
}) => {
  return (
    <StyledContainer style={style}>
      <LoadingSpan></LoadingSpan>
      <div style={{ position: "relative", display: "none" }}>
        <LoadingStyled opacity={afterOpacity}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </LoadingStyled>
        {text && <StyledText>{text}</StyledText>}
      </div>
    </StyledContainer>
  );
};

export default Loading;
