/** @format */

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { any } from "ramda";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import { echartsColors } from "constants/colors";

const StyleLegendContainer = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const StyledScrollDiv = styled.div`
  width: max-content;
  overflow-x: auto;
  padding-bottom: 8px;
  transition: transform 0.3s ease-in-out;
  ::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.3rem !important;
  }
`;

const StyledLegend = styled.ul`
  width: max-content;
  display: flex;
  gap: 10px;
  z-index: 0;
  list-style: none;
`;

const StyledLegendLi = styled.li`
  text-align: center;
  display: flex;
  gap: 5px;
`;

const StyledScrollButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const StyledlegendIcon = styled.div<{ idxname?: string }>`
  font-weight: 500;
  font-size: 12px;
  line-height: ${(props) => (props.idxname ? "22px" : "32px")};
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  column-gap: 8px;
  &::before {
    content: "${(props: { idxname?: string }) => props.idxname || ""}";
    display: flex;
    border-radius: 4px;
    width: ${(props: { idxname?: string }) =>
      props.idxname ? "20px" : "10px"};
    height: ${(props: { idxname?: string }) =>
      props.idxname ? "20px" : "10px"};
    justify-content: center;
    color: ${(props: { idxname?: string }) => (props.idxname ? "#fff" : "")};
    background: ${(props) => props.color || "#fff"};
  }
`;

const StyledLegendLabel = styled.div<{
  ishide: any;
}>`
  font-size: 12px;
  color: ${(props) => (props.ishide ? "#d3d1d1" : "#949494")};
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  text-align: center;
  border: 0;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    svg {
      color: #229bff;
    }
  }
`;

const Legend = ({
  data,
  hideList = [],
  style = {},
  itemsPerPage = 5,
}: {
  data?: any;
  hideList?: string[];
  style?: any;
  itemsPerPage?: number;
}) => {
  const legendRef: any = useRef(null);
  const legendULRef: any = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const totalPages = Math.ceil(data.length / itemsPerPage);

  useEffect(() => {
    const container = legendRef.current;
    const ULcontainer = legendULRef.current;
    const containerWidth = container.offsetWidth;
    const ULContainerWidth = ULcontainer.offsetWidth;
    const calculatedTotalPages = Math.ceil(ULContainerWidth / containerWidth);

    setTotalPages(calculatedTotalPages);
  }, [data]);

  useEffect(() => {
    const container: any = legendRef.current;
    const scrollPosition = container.offsetWidth * (currentPage - 1);
    container.scrollLeft = scrollPosition;
  }, [currentPage]);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <div style={{ ...style, width: "80%", marginLeft: "10%" }}>
      <StyleLegendContainer>
        <StyledScrollDiv ref={legendRef}>
          <StyledLegend ref={legendULRef}>
            {data.map((item: any, index: number) => {
              const isHide = any((i) => i === item.key)(hideList);
              return (
                <StyledLegendLi
                  key={index}
                  className="legend-item"
                  style={{ cursor: item.onclick && "pointer" }}
                  onClick={() => {
                    if (item.onclick && typeof item.onclick === "function") {
                      item.onclick(item);
                    }
                  }}
                >
                  <StyledlegendIcon
                    color={
                      isHide ? "#d3d1d1" : echartsColors[index] || "#55a6f3"
                    }
                    idxname={item.key || ""}
                  />
                  <StyledLegendLabel ishide={isHide ? 1 : 0}>
                    {item.name}
                  </StyledLegendLabel>
                </StyledLegendLi>
              );
            })}
          </StyledLegend>
        </StyledScrollDiv>

        <StyledScrollButtons>
          {currentPage > 1 && (
            <StyledButton onClick={handlePrevPage}>
              <CaretUpOutlined style={{ color: "#949494" }} />
            </StyledButton>
          )}
          {totalPages > 1 && <div>{`${currentPage}/${totalPages}`}</div>}
          {currentPage < totalPages && (
            <StyledButton onClick={handleNextPage}>
              <CaretDownOutlined style={{ color: "#949494" }} />
            </StyledButton>
          )}
        </StyledScrollButtons>
      </StyleLegendContainer>
    </div>
  );
};

export default Legend;
