/** @format */

import { find, propEq } from "ramda";

export const findProject = (id: string, data: []) => {
  return find(propEq(id, "id"))(data || []) || { displayName: "" };
};

export const setMode = (mode: string) => {
  if (!mode || mode === "expand") {
    return "collapsed";
  } else {
    return "expand";
  }
};

export const selectProjectSpring = (mode?: string) => {
  return {
    width: mode === "collapsed" ? 28 : 176,
    marginRight: mode === "collapsed" ? "11px" : "12px",
    marginBottom: mode === "collapsed" ? "1px" : "8px",
    marginLeft: mode === "collapsed" ? "11px" : "8px",
    paddingRight: mode === "collapsed" ? "8px" : "12px",
    paddingLeft: mode === "collapsed" ? "8px" : "12px",
    config: { duration: 150 },
  };
};
