/** @format */

import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { range, addIndex, map, path, sum, join } from "ramda";
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkPointComponent,
  MarkPointComponentOption,
} from "echarts/components";
import { PieChart, PieSeriesOption } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

import AdaptiveWidget from "components/shared/elements/AdaptiveWidget";
import { echartsColors } from "constants/colors";
import { pieData } from "./DataHelper";
import { formatNumberWithUnit, numberWithUnit } from "utils/stringUtil";

const StyledContainer = styled(AdaptiveWidget)`
  background: #fff;
  width: 100%;
  height: ${(props) => `calc(100% - ${props.headerHeight || 0}px)`};
  border-radius: 4px;
  position: relative;
`;

const StyledTotals = styled.div`
  width: 100%;
  position: absolute;
  top: 42%;
  display: flex;
  z-index: 0;
`;

const StyledTotal = styled.div`
  display: flex;
  top: 50%;
  justify-content: center;
  left: calc((100% - 158px) / 2);
`;

const StyledInside = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledContent = styled.div`
  text-align: center;
  width: 100%;
`;

const StyledTotalTitle = styled.div`
  font-size: 12px;
  color: #949494;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const StyledTotalNum = styled.div`
  font-size: 26px;
`;

const Pie = ({
  item,
  data,
  name,
  headerHeight,
}: {
  item?: any;
  data?: any;
  name?: string;
  headerHeight?: number;
}) => {
  const mapRef: any = useRef(null);

  const [itemSize, setItemSize] = useState<{ width: number; height: number }>({
    width: 1,
    height: 1,
  });
  const [initChart, setInitChart] = useState<any>();
  const [totals, setTotals] = useState<{}[]>([]);

  const widgetInfo = useMemo(() => {
    return path(["widget"], item);
  }, [item]);

  const units = useMemo(() => {
    return path(["units"], data);
  }, [data]);

  type EChartsOption = echarts.ComposeOption<
    | TooltipComponentOption
    | LegendComponentOption
    | PieSeriesOption
    | MarkPointComponentOption
  >;

  echarts.use([
    MarkPointComponent,

    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout,
  ]);

  useEffect(() => {
    const myChart = echarts.init(mapRef.current);
    setInitChart(myChart);
  }, []);

  useEffect(() => {
    if (initChart) {
      initChart.resize();
    }
  }, [initChart, itemSize]);

  useEffect(() => {
    const [seriesSource, total] = pieData(
      data,
      path(["analysis", "formulas"], widgetInfo) || [],
    );
    setTotals(total);
    const option: EChartsOption = {
      tooltip: {
        trigger: "item",
        appendToBody: true,
        enterable: true,
      },
      color: echartsColors,
      legend: {
        show: false,
        type: "scroll",
        left: 0,
        itemWidth: 14,
      },
      series: seriesSource,
    };

    if (initChart) {
      initChart.setOption(option, true);
    }
  }, [initChart, data, name, widgetInfo, units]);

  let textSize: number = 132;
  if (itemSize.width / totals.length < 281) {
    textSize = (1 - (281 - itemSize.width / totals.length) / 281) * 132;
  }

  const setScale = (length: number) => {
    const fullWidth = 26 * length;
    if (fullWidth > textSize) {
      const scale = textSize / length / 20;
      return scale > 1 ? 1 : scale;
    }
    return 1;
  };

  const totalNodes: any = addIndex(map)(
    (i: any, idx: number) => (
      <StyledTotal
        style={{ width: `${100 / totals.length}%` }}
        key={`${i}-${idx}`}
      >
        <StyledInside
          style={{
            width: `${textSize}px`,
          }}
        >
          <StyledContent>
            <StyledTotalTitle>{`Total ${i.label}`}</StyledTotalTitle>
            <StyledTotalNum
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  transform: `scale(${setScale(i.total.toString().length)})`,
                  transformOrigin: "center center",
                }}
              >
                {formatNumberWithUnit(i.total)}
              </span>
            </StyledTotalNum>
          </StyledContent>
        </StyledInside>
      </StyledTotal>
    ),
    totals,
  );

  return (
    <StyledContainer setItemSize={setItemSize} headerHeight={headerHeight}>
      <div ref={mapRef} style={{ height: "100%", zIndex: 1 }} />
      <StyledTotals>{totalNodes}</StyledTotals>
    </StyledContainer>
  );
};

export default Pie;
