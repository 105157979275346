import React, { FC } from "react";

const OpenPopup: FC<any> = ({
  width = 32,
  height = 32,
  fill = "#647379",
  className,
  ...restProps
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <circle
      opacity="0.6"
      cx="16.7832"
      cy="16"
      r="15.5"
      fill="#AAAAAA"
      stroke="#AAAAAA"
    />
    <rect x="8.7832" y="10" width="2" height="2" fill="white" />
    <rect x="8.7832" y="15" width="2" height="2" fill="white" />
    <rect x="8.7832" y="20" width="2" height="2" fill="white" />
    <rect x="12.7832" y="10" width="12" height="2" fill="white" />
    <rect x="12.7832" y="15" width="12" height="2" fill="white" />
    <rect x="12.7832" y="20" width="12" height="2" fill="white" />
  </svg>
);

export default OpenPopup;
