/** @format */

import { path, map } from "ramda";
import { useQueryClient, useMutation } from "react-query";

import { Toast } from "components/shared/elements/Toast";
import {
  ENVIRONMENT_LIST,
  PROJECT_LIST,
  PROJECT,
  EVENT_LIST,
  FUNNEL_ANALYSIS,
  ATTRIBUTE_USER_ALL,
  ATTRIBUTE_EVENT_ALL,
} from "constants/queryKeys";

export const onError = (e: any) => {
  if (e && e.code !== 409) {
    Toast(
      {
        message: e.message || "Failed",
      },
      "error"
    );
  }
};

export const useConfig = (
  queryKey: any,
  callback: (target: any, old?: any[]) => any[],
  type: string
) => {
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => queryClient.invalidateQueries(queryKey),
    async onMutate(target: any) {
      const previousItems = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old?: any[]) => {
        return callback(target, old);
      });
      return { previousItems };
    },
    onError: (error: any, newItem: any, context: any) => {
      queryClient.setQueryData(queryKey, context.previousItems);
      onError(error);
    },
  };
  return options;
};

export const useDeleteConfig = (queryKey: any, type: string) =>
  useConfig(
    queryKey,
    (target, old) => old?.filter((item) => item.id !== target.id) || [],
    type
  );

export const useUpdateConfig = (queryKey: any, type: string) =>
  useConfig(
    queryKey,
    (target: any, old: any) => {
      if (old) {
        if (type === "list") {
          const elements = path(["elements"], old) || [];
          const updateElements =
            map((item: any) => {
              return item.id === target.id ? { ...item, ...target.body } : item;
            }, elements) || [];

          return {
            ...old,
            elements: updateElements,
          };
        }
        if (type === "detail") {
          return { ...old, ...target.body };
        }
      }
    },
    type
  );

export const useAddConfig = (queryKey: any, type: string) =>
  useConfig(queryKey, (target, old) => (old ? [...old, target] : []), type);

export const useUpdateData = <ParamType = any>(
  queryKey: any,
  mutationFn: (value: ParamType) => any,
  options?: any,
  customError?: boolean
) => {
  const queryClient = useQueryClient();
  const defaultOptions = {
    onSuccess: () => {
      if (queryKey) {
        queryClient.invalidateQueries(queryKey);
      }
    },
    onError: (e: any) => {
      if (!customError) {
        onError(e);
      }
    },
  };
  return useMutation(mutationFn, options || defaultOptions);
};

const mins = 5 * 60 * 1000;

export const queryOptions = { retry: 1 };

export const queryCacheOptions = {
  refetchOnWindowFocus: false,
  refetchInterval: mins - 1000,
};

export const useCacheData = (key: any, projectId?: any) => {
  const queryClients = useQueryClient();
  const keys: any = {
    environments: [ENVIRONMENT_LIST, projectId],
    projects: PROJECT_LIST,
    project: PROJECT,
    events: EVENT_LIST,
    funnel: FUNNEL_ANALYSIS,
    attributeUserAll: ATTRIBUTE_USER_ALL,
    attributeEventAll: ATTRIBUTE_EVENT_ALL,
  };
  if (!keys[key]) {
    queryClients.getQueryData(key);
  }

  return queryClients.getQueryData(keys[key]);
};
