import React, { FC, useEffect, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { throttle } from "throttle-debounce";

interface AdaptiveWidgetProps {
  setItemSize?: (obj: { width: number; height: number }) => void;
  headerHeight?: Number;
  style?: {};
  children: any;
  className?: any;
}

const AdaptiveWidget: FC<AdaptiveWidgetProps> = ({
  setItemSize = () => {},
  className,
  headerHeight = 48,
  style = {},
  children,
}) => {
  const itemRef: any = useRef(null);

  const throttleFunc = throttle(1000, (entry) => {
    setItemSize({
      width: entry.contentRect.width,
      height: entry.contentRect.height,
    });
  });

  const robserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      switch (entry.target) {
        case itemRef.current:
          throttleFunc(entry);
          break;
      }
    }
  });

  useEffect(() => {
    if (itemRef) {
      robserver.observe(itemRef.current);
    }
  }, [itemRef]);

  return (
    <div
      className={className}
      ref={itemRef}
      style={{
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default AdaptiveWidget;
