import EqrcodeCommonFilter from "./eqrcode/EqrcodeCommonFilter";

import React, {
  useState,
  FC,
  useEffect,
  useMemo,
  useCallback,
  memo,
  useRef,
} from "react";
import styled from "styled-components";
// import CsvExportor from "csv-exportor";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
} from "echarts/components";
import { MapChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { SVGRenderer } from "echarts/renderers";
import {
  map,
  addIndex,
  sortBy,
  sort,
  descend,
  prop,
  filter,
  path,
  sum,
} from "ramda";
import { animated, useSpring } from "@react-spring/web";

import ItemList from "components/shared/table/ItemList";
import { calcHeightByNumTypes } from "./Helper";
import * as api from "api";
import chinaJson from "constants/map/china";
import province from "constants/province";
import AdaptiveWidget from "components/shared/elements/AdaptiveWidget";
import OpenPopup from "assets/dashboard/OpenPopup";
import ColseIcon from "assets/dashboard/Colse";

const StyledContainer = styled(AdaptiveWidget)`
  width: 100%;
  height: ${(props) => `calc(100% - ${props.headerHeight || 0}px)`};
`;

const StyledMapContainer = styled.div`
  height: calc(100% - 1px);
  position: relative;
  overflow: hidden;
`;

const StyledMapNode = styled.div`
  height: 100%;
`;

const StyledOpenPopup = styled(OpenPopup)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 2;
`;

const StyledPopupTable = styled.div`
  width: calc(100% - 42px);
  height: 97%;
  padding: 10px 20px 20px;
  right: 0px;
  position: absolute;
  backdrop-filter: blur(2px);
  border-radius: 4px;
`;

const StyledPopupTitle = styled.div`
  height: 41px;
  line-height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
`;

const StyledColse = styled(ColseIcon)`
  cursor: pointer;
  fill: #aaaaaa;
`;

const StyledBigSize = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
`;

const StyledBigMap = styled.div``;

const StyledBigTable = styled.div`
  padding-left: 50px;
`;

const columns = () => [
  {
    title: "Rank",
    dataIndex: "rank",
    key: "rank",
    width: "20%",
    align: "left",
    render: (text: any, record: any, idx: number) => {
      return (
        <span
          data-auid={`auid_dashboard_eqrcode${idx}`}
          style={{
            display: "inline-block",
            width: "19px",
            fontWeight: 500,
          }}
        >
          {text}
        </span>
      );
    },
  },
  {
    title: "Province",
    dataIndex: "province",
    key: "province",
    width: "30%",
    align: "left",
    render: (text: any) => {
      return <span>{text}</span>;
    },
  },
  {
    title: "Online Devices Count",
    dataIndex: "count",
    key: "count",
    width: "50%",
    align: "left",
    render: (text: any) => {
      return <span style={{ color: "#2E2E2E", fontSize: "14px" }}>{text}</span>;
    },
  },
];

interface Item {
  count: number;
  province: string;
}

interface MapProps {
  item?: any;
  data?: any;
  barSeries?: [];
  showDataZoom?: boolean;
  headerHeight?: number;
  nodeRef?: any;
  fetchQuery?: any;
}

const hexToRgba = (hex: any, alpha: any) => {
  var r = parseInt(hex.slice(1, 3), 16);
  var g = parseInt(hex.slice(3, 5), 16);
  var b = parseInt(hex.slice(5, 7), 16);
  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
};

const ChinaMap: FC<MapProps> = ({
  data,
  item,
  headerHeight,
  nodeRef,
  fetchQuery = () => {},
}) => {
  const [initChart, setInitChart] = useState<any>();
  const [height, setHeight] = useState<string>();
  const [itemSize, setItemSize] = useState({ width: 1, height: 1 });
  const [isVisiableTable, toggleVisiableTable] = useState(false);
  const [requestQuery, setRequestQuery] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);
  const mapRef = useRef<any>(null);

  const widgetInfo = useMemo(() => {
    return path(["widget"], item);
  }, [item]);

  const layout: any = path(["layout"], item);
  const analysis: any = path(["analysis"], widgetInfo);
  const rows: any = path(["details"], data);
  const isLarge = path(["w"], layout) === 4;

  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    VisualMapComponent,
    GeoComponent,
    MapChart,
    CanvasRenderer,
    SVGRenderer,
  ]);

  useEffect(() => {
    const myChart: any = echarts.init(mapRef.current);
    setInitChart(myChart);
  }, [layout]);

  useEffect(() => {
    if (initChart) {
      initChart.resize();
    }
  }, [initChart, itemSize, height]);

  useEffect(() => {
    setHeight(calcHeightByNumTypes(widgetInfo));
  }, [widgetInfo]);

  const onChangeDate = (dateObjStrings: any) => {
    setRequestQuery(dateObjStrings);
    fetchQuery({ ...analysis, ...dateObjStrings });
  };

  const popupHeight = itemSize.height - 1;

  const cityData = filter(
    (i: any) => i.name,
    map((i: any) => {
      return {
        name: province[path(["byValues", 0], i)],
        value: sum(map((v) => sum(v), i.values)),
      };
    }, rows || []),
  );

  let max = 0;

  const descendingComparator: any = descend((i: Item) => i.count);

  const sortData: Item[] = map((i: any) => {
    if (i.value > max) {
      max = i.value;
    }
    return { province: path(["name"], i), count: i.value };
  }, cityData || []);

  const cityPopupData = addIndex(map)(
    (i: any, idx: number) => ({ ...i, rank: idx + 1 }),
    sort(descendingComparator, sortData),
  );

  useEffect(() => {
    if (initChart) {
      echarts.registerMap("china", chinaJson);
      const inRangeColor =
        cityData.length < 1
          ? ["#f3f3f3"]
          : [
              "#C2F1F8",
              "#A5D9FF",
              "#85BAFF",
              "#639BFF",
              "#4080FF",
              "#246DFF",
              "#0055FF",
              //   "#FFE2CA",
              //   "#FFB794",
              //   "#FF9A79",
              //   "#FF825E",
              //   "#FF654B",
              //   "#E84330",
              //   "#C7201A",
              //   "#A80003",
            ];

      const option = {
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
        },
        visualMap: {
          left: "left",
          min: 0,
          max,
          inRange: {
            color: inRangeColor,
          },
          text: ["High", "Low"],
          calculable: true,
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            name: "China",
            type: "map",
            map: "china",
            label: { position: "left" },
            emphasis: {
              label: {
                show: false,
              },
              itemStyle: {
                borderColor: "#003895",
                areaColor: null,
                borderWidth: 1,
              },
            },
            itemStyle: {
              borderColor: "#2B94F8",
              // emphasis: { areaColor: "#6CBCFF" },
            },
            select: {
              itemStyle: {
                //  areaColor: "#6CBCFF",
              },
            },
            data: cityData,
          },
        ],
      };

      initChart.setOption(option);

      initChart.on("mouseover", function (params: any) {
        var name = params.name;
        var data = params.data;
      });
    }
  }, [initChart, cityData, max]);

  const onDownload = async () => {
    setDownloadLoading(true);
    try {
      // const result = await api.fetchRenderDownload({
      //   environmentId: path(["queries", "environmentId"], widgetWithPanel),
      //   projectId: path(["id"], project),
      //   downloadType: "province",
      //   unit: "day",
      //   aggregator: "sum",
      //   groupBy: [
      //     {
      //       key: "province",
      //       value: "$custom_attributes.province",
      //     },
      //   ],
      //   ...getTimeAt(requestQuery),
      // });
      // const downloadResult = strToArrByComma(
      //   oneToTwo(strToArr(base64Decode(result?.content)))
      // );
      // CsvExportor.downloadCsv(
      //   downloadResult,
      //   { header: null },
      //   "activeDevicesMap.csv"
      // );
    } catch (e) {
      // basicErrorHandler(`Failed to download file.`)(e);
    }
    setDownloadLoading(false);
  };

  const styles = useSpring({
    opacity: isVisiableTable ? 1 : 0,
    transform: `translateY(${isVisiableTable ? `-${popupHeight}` : 0}px)`,
  });

  const Buttonstyles = useSpring({
    opacity: isVisiableTable ? 0 : 1,
  });

  const popupTable = (
    <StyledPopupTable
      style={{
        background: isLarge ? "none" : "rgba(224, 247, 255, 0.9)",
      }}
    >
      {!isLarge && (
        <StyledPopupTitle>
          Activation equipment in all provinces
          <StyledColse onClick={() => toggleVisiableTable(false)} />
        </StyledPopupTitle>
      )}

      <div style={{ height: "calc(100% - 41px)" }}>
        <ItemList
          columns={columns()}
          dataSource={cityPopupData}
          headerStyle={{
            background: isLarge
              ? "rgba(215, 242, 255, 1)"
              : "rgba(196, 228, 255, 0.7)",
          }}
          bodyStyle={{
            overflowY: "auto",
            height: itemSize.height - 100 || (isLarge ? "400px" : "360px"),
            background: isLarge && "rgba(245, 253, 255, 1)",
            marginTop: 0,
          }}
          inBodyStyle={{ height: "calc(100% - 123px)" }}
          isScroll={true}
        />
      </div>
    </StyledPopupTable>
  );

  const mapNode = <StyledMapNode ref={mapRef} />;

  return (
    <StyledContainer
      setItemSize={setItemSize}
      headerHeight={headerHeight}
      style={{ width: "100%" }}
    >
      {/* <EqrcodeCommonFilter
        nodeRef={nodeRef}
        handleChangeDate={onChangeDate}
        onDownload={onDownload}
        downloadLoading={downloadLoading}
      /> */}
      <StyledMapContainer className="nonDraggable">
        {isLarge ? (
          <StyledBigSize>
            <StyledBigMap>{mapNode}</StyledBigMap>
            <StyledBigTable>
              <div
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                {popupTable}
              </div>
            </StyledBigTable>
          </StyledBigSize>
        ) : (
          <>
            <animated.div style={Buttonstyles}>
              <StyledOpenPopup onClick={() => toggleVisiableTable(true)} />
            </animated.div>
            {mapNode}
            <animated.div
              style={{
                ...styles,
                width: "100%",
                height: `${popupHeight}px`,
                right: "0px",
                position: "absolute",
              }}
            >
              {popupTable}
            </animated.div>
          </>
        )}
      </StyledMapContainer>
    </StyledContainer>
  );
};

export default ChinaMap;
