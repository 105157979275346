/** @format */

import React, { useReducer, FC } from "react";
import { createStore } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { tableState, TableStateProps } from "./module/table";

interface InsightsProps {
  bears: number;
}

interface InsightsState extends InsightsProps, TableStateProps {
  addBear: () => void;
}

export type InsightsStore = ReturnType<typeof createInsightsStore>;

const createInsightsStore = (initProps?: Partial<InsightsProps>) => {
  const DEFAULT_PROPS: InsightsProps = {
    bears: 2,
  };
  const persistData: any = persist(
    (set, get: any) => ({
      ...DEFAULT_PROPS,
      ...initProps,
      addABear: () => set({ sort: get().bears + 1 }),
      ...tableState(set, get),
    }),
    {
      name: "horizon/insights-storage",
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state: any) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !["bears"].includes(key)),
        ),
    },
  );
  return createStore<InsightsState>()(persistData);
};

export default createInsightsStore;
