/** @format */

function emptyAction() {
  // console.warn("Current execute action is empty!");
}

class Actions {
  actions = {
    onGlobalStateChange: emptyAction,
    setGlobalState: emptyAction,
  };

  setActions(actions: any) {
    this.actions = actions;
  }

  onGlobalStateChange(...args: any) {
    return this.actions.onGlobalStateChange.apply(null, args);
  }

  setGlobalState(...args: any) {
    return this.actions.setGlobalState.apply(null, args);
  }
}

const actions = new Actions();

export default actions;
